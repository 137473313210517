import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getHall } from "../../redux/features/onPageSlice";
import { Link } from 'react-router-dom';
// import Sidebarads from '../layout/Sidebarads';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Errorshow from '../layout/Errorshow';
import Loader from '../layout/Loader';

const HtagAll = () => {

    const dispatch = useDispatch();
    // const [myUrl, SetMyUrl] = useState();
    const { errorCode, resone, result } = useSelector((state) => state.OnPagAPI.hall)
    const loading = useSelector((state) => state.OnPagAPI.isLoading)
    const error = useSelector((state) => state.OnPagAPI.Error)
    const website = useSelector((state) => state.OnPagAPI.website)
    // console.log(errorCode)
    // console.log(result)

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: {
            website: website,
        },
        validationSchema: Yup.object({
            website: Yup.string().url().required('Please Enter Valid Website URL'),
        }),
        onSubmit: (data) => {
            //console.warn('Form Submited', data);
            // let url = data.website.split("/");
            // SetMyUrl(url[0] + "//" + url[2])
            dispatch(getHall(data));
        }
    })

    return (
        <>
            <section className="py-5">

                <div className="container px-5 my-5">
                    <div className="row">
                        <div className="col-12">
                            <Link to="/" className="btn btn-outline-dark"><i className="bi bi-arrow-left me-2"></i>Back</Link>
                        </div>
                    </div>
                    <div className="text-center mb-5">
                        <h1 className="fw-bolder">Header Tag Checker</h1>
                        <p className="lead  text-muted mb-0">Header Tag Checker is a tool made use of to examine the structure of a website's header tags. It assists webmasters & SEO professionals to establish any kind of type of issues with the header tags, such as missing out on tags, unreliable nesting, & incorrect syntax. The tool can also be made use of to compare the header tags of different websites to see specifically just how they are structured. This can be useful for determining any kind of prospective problems that can be affecting the website's SEO performance.</p>
                    </div>
                    <div className="row gx-5">
                        <div className="col-xl-1"></div>

                        <div className="col-xl-10">

                            <div className="row">
                                {/* <div className="col-12 "> px-5 */}
                                <div className="col-12 ">
                                    <form onSubmit={handleSubmit} >
                                        <label className='form-label'><b>Enter Website URL*</b></label>
                                        <div className="input-group mb-4">
                                            <input type="text"
                                                id="website"
                                                name="website"
                                                className="form-control"
                                                placeholder="Website Url"
                                                aria-label="Recipient's username"
                                                onChange={handleChange}
                                                value={values.name}
                                                onBlur={handleBlur}
                                                aria-describedby="button-addon2" />

                                            <button className="btn btn-lg btn-dark" type="submit" id="button-addon2">Check</button>
                                        </div>

                                        {(errors.website && touched.website) ? <div className="alert alert-danger mb-4" role="alert">{errors.website}</div> : ""}
                                    </form>
                                </div>
                            </div>
                            <hr />


                            {loading ?
                                <Loader />
                                : ""}


                            {error && errorCode !== 200 ?
                                <Errorshow error={error} />
                                : ""}


                            {errorCode === 200 ?
                                <>
                                    <div className="row">
                                        <div className="col-12">
                                            <h5>Result : </h5>
                                            <div className={`alert alert-${result.alert}`} role="alert">
                                                <h4 className="alert-heading">Website : {website}</h4>
                                                <p className="mb-0 fs-6">{result.alertmsg}</p>
                                                <p className="mb-2 mt-3 badge bg-secondary fs-6">Headers Tags Count : {result.headCount}</p>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row gx-5">
                                        <div className="col-md-12">
                                            <table className="table table-striped table-fluid table-part cs-table-icon">
                                                <thead className="bg-dark text-white ">
                                                    <tr>
                                                        <th className="ms-3">Header Name</th>
                                                        <th className="text-center">Frequency</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="text-center">&lt;H2&gt; &lt;H2/&gt;</td>
                                                        <td className="text-center">{result.headCountArr.H2}</td>
                                                    </tr>

                                                    <tr>
                                                        <td className="text-center">&lt;H3&gt; &lt;H3/&gt;</td>
                                                        <td className="text-center">{result.headCountArr.H3}</td>
                                                    </tr>

                                                    <tr>
                                                        <td className="text-center">&lt;H4&gt; &lt;H4/&gt;</td>
                                                        <td className="text-center">{result.headCountArr.H4}</td>
                                                    </tr>

                                                    <tr>
                                                        <td className="text-center">&lt;H5&gt; &lt;H5/&gt;</td>
                                                        <td className="text-center">{result.headCountArr.H5}</td>
                                                    </tr>

                                                    <tr>
                                                        <td className="text-center">&lt;H6&gt; &lt;H6/&gt;</td>
                                                        <td className="text-center">{result.headCountArr.H6}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className="row gx-5">
                                        <div className="col-md-12">
                                            <table className="table table-striped table-fluid table-part cs-table-icon">
                                                <thead className="bg-dark text-white ">
                                                    <tr>
                                                        <th className="ms-3">H2-H6 Header Title</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {result.Headings.map((hd) => (
                                                        <tr>
                                                            <td>&lt;{hd.head}&gt; {hd.Title} &lt;{hd.head}/&gt;</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </>

                                : ""}



                            {errorCode === 400 ?

                                <div className="row">
                                    <div className="col-12">
                                        <div className={`alert alert-${result.alert}`} role="alert">
                                            <h4 className="alert-heading">{resone}</h4>
                                        </div>
                                    </div>
                                </div>

                                : ""}



                            <div className="row ">
                                <div className="col-12">

                                    <div className="alert alert-info mb-4" role="alert">
                                        <h4 className="alert-heading">What it is</h4>
                                        <p>The H2-H6 Header Tags are an important way of organizing the content of your page & signaling to Search Engines the longer tail topics your page need to rank for.</p>
                                    </div>

                                    <div className="alert alert-warning mb-4" role="alert">
                                        <h4 className="alert-heading">How to fix it</h4>
                                        <p>We recommend including at the very least 2 other Header Tag levels on your page (such as H2 & also H3) in addition to the H1. It is useful to likewise include important keywords in these Header Tags. These would be added to the core content section of your page.</p>
                                    </div>
                                </div>
                            </div>




                        </div>

                        <div className="col-xl-1">
                            {/* <Sidebarads /> */}
                        </div>


                    </div>

                    <center><h2 className="fw-bolder text-clr mb-5">Why Header Tag Checker Tool? </h2></center>
                    <div className="row gx-5">
                        <div className="col-lg-12 lead text-muted text-justify">

                            <p>
                                header tags, such as missing out on tags, incorrect nesting, in addition to unreliable syntax. The tool can also be used to contrast the header tags of different websites to see just how they are structured.
                            </p>
                            <p>
                                Header tags are an important part of SEO, as they help search engines identify the structure of a website. They are additionally made use of to supply added information pertaining to the content of a page, such as the title, description, & keywords.
                            </p>
                            <p>
                                Taking advantage of a Header Tag Checker can aid webmasters along with SEO professionals to figure out any kind of issues with the header tags on their website. It can additionally be made use of to contrast the header tags of different websites to see specifically how they are structured. This can be valuable for identifying any kind of sort of prospective problems that might be influencing the website's SEO performance.
                            </p>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HtagAll
