import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getTitle } from "../../redux/features/onPageSlice";
import { Link } from 'react-router-dom';
// import Sidebarads from '../layout/Sidebarads';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Errorshow from '../layout/Errorshow';
import Loader from '../layout/Loader';

const TitleTag = () => {

    const dispatch = useDispatch();
    // const [myUrl, SetMyUrl] = useState();
    const { errorCode, resone, result } = useSelector((state) => state.OnPagAPI.title)
    const loading = useSelector((state) => state.OnPagAPI.isLoading)
    const error = useSelector((state) => state.OnPagAPI.Error)
    const website = useSelector((state) => state.OnPagAPI.website)
    // console.log(errorCode)
    // console.log(result)

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: {
            website: website,
        },
        validationSchema: Yup.object({
            website: Yup.string().url().required('Please Enter Valid Website URL'),
        }),
        onSubmit: (data) => {
            //console.warn('Form Submited', data);
            // let url = data.website.split("/");
            // SetMyUrl(url[0] + "//" + url[2])
            dispatch(getTitle(data));
        }
    })

    return (
        <>
            <section className="py-5">

                <div className="container px-5 my-5">
                    <div className="row">
                        <div className="col-12">
                            <Link to="/" className="btn btn-outline-dark"><i className="bi bi-arrow-left me-2"></i>Back</Link>
                        </div>
                    </div>
                    <div className="text-center mb-5">
                        <h1 className="fw-bolder">Title Tag Checker</h1>
                        <p className="lead  text-muted mb-0">The Title Tag checker is an crucial HTML element that lets users as well as search engines know what the topic of the webpage is and what keywords the page needs to rank for. In a user's browser, the Title will appear in the Header Bar. The keyword density is a great On-Page SEO factor (and one of the easiest to improve).</p>
                    </div>
                    <div className="row gx-5">
                        <div className="col-xl-1"></div>
                        <div className="col-xl-10">

                            <div className="row">
                                {/* <div className="col-12 "> px-5 */}
                                <div className="col-12 ">
                                    <form onSubmit={handleSubmit} >
                                        <label className='form-label'><b>Enter Website URL*</b></label>
                                        <div className="input-group mb-4">
                                            <input type="text"
                                                id="website"
                                                name="website"
                                                className="form-control"
                                                placeholder="Website Url"
                                                aria-label="Recipient's username"
                                                onChange={handleChange}
                                                value={values.name}
                                                onBlur={handleBlur}
                                                aria-describedby="button-addon2" />

                                            <button className="btn btn-lg btn-dark" type="submit" id="button-addon2">Check</button>
                                        </div>

                                        {(errors.website && touched.website) ? <div className="alert alert-danger mb-4" role="alert">{errors.website}</div> : ""}
                                    </form>
                                </div>
                            </div>
                            <hr />


                            {loading ?
                                <Loader />
                                : ""}


                            {error && errorCode !== 200 ?
                                <Errorshow error={error} />
                                : ""}


                            {errorCode === 200 ?

                                <div className="row">
                                    <div className="col-12">
                                        <h5>Result : </h5>
                                        <div className={`alert alert-${result.alert}`} role="alert">
                                            <h4 className="alert-heading">Website : {website}</h4>

                                            <p className="fs-5"><span className="mb-0 badge bg-secondary fs-6">Title : </span> {result.title}</p>

                                            <p className="mt-2">{result.alertmsg}</p>

                                            <p className="mb-0 badge bg-secondary fs-6">Length : {result.pageLength}</p>
                                        </div>
                                    </div>
                                </div>

                                : ""}

                            {errorCode === 400 ?

                                <div className="row">
                                    <div className="col-12">
                                        <div className={`alert alert-${result.alert}`} role="alert">
                                            <h4 className="alert-heading">{resone}</h4>
                                        </div>
                                    </div>
                                </div>

                                : ""}

                            <div className="row ">
                                <div className="col-12">

                                    <div className="alert alert-info mb-4" role="alert">
                                        <h4 className="alert-heading">What it is</h4>
                                        <p>The Title Tag is an essential HTML aspect that tells users as well as Search Engines what the topic of the webpage is & the kind of keywords the page need to rate for. The Title will certainly show up in the Header Bar of a user's browser. It is additionally among one of the most vital (as well as easiest to boost) On-Page SEO factors.</p>
                                    </div>

                                    <div className="alert alert-warning mb-4" role="alert">
                                        <h4 className="alert-heading">How to fix it</h4>
                                        <p>We suggest setting a keyword abundant Title in between 10-- 70 characters. This is commonly straightforward to enter into your CMS system or might require to be by hand set in the header section of the HTML code.</p>
                                    </div>
                                </div>
                            </div>




                        </div>

                        <div className="col-xl-1">
                            {/* <Sidebarads /> */}
                        </div>


                    </div>

                    <center><h2 className="fw-bolder text-clr mb-5">Why Title Tag Checker Tool? </h2></center>
                    <div className="row gx-5">
                        <div className="col-lg-12 lead text-muted text-justify">

                            <p>
                                Title tags are an essential part of any sort of digital marketing strategy, as they are an important consider developing precisely how well a website rates in search engine results. As such, it is vital to use a title tag checker tool to guarantee the title tags of a website are up to date & maximized.
                            </p>
                            <p>
                                Title tag checkers are tools that check a website's source code & discover any type of issues connected to the titles of web pages. They can be utilized to locate errors in the title tags, such as missing words, wrong capitalization, or incorrect length. It can likewise spot replicate title tags, which can trigger search engines to punish the website.
                            </p>
                            <p>
                                The importance of title tags can not be overemphasized, as they are a substantial consider exactly how website rates in organic search results. Title tags are the extremely initial thing a user sees when looking for something, so it is very essential to ensure they are maximized & likewise essential to the content of the page. A title tag checker can assist identify any type of issues with the titles & also can use suggestions for boosting them.
                            </p>
                            <p>
                                In addition to inspecting title tags, a title tag checker can additionally examine the meta description of a website. Meta descriptions are brief recaps of a web page's content, & also they are usually presented in search engine results. It is important to guarantee that the meta description is properly mirroring the content of the page & also is maximized for the target keywords. Title tag checkers can detect any type of issues with the meta descriptions & also can provide suggestions for enhancing them.
                            </p>
                            <p>
                                There are a variety of title tag checkers available, both cost-free & also paid. Several of the lot more prominent tools consist of Moz, Screaming Frog, & also Woorank. Each of these tools uses a variety of features & also functions, so it is essential to research the different options prior to selecting one.
                            </p>
                            <p>
                                Utilizing a title tag checker is vital for any website that intends to be successful in online search engine rankings. Title tags are an integral part of SEO, as well as using a title tag checker can help make certain that the website is properly reflecting the content of the page & also is enhanced for the target keywords. A title tag checker can likewise help recognize any kind of errors or inaccuracies in the title tags, in addition to any kind of issues with the meta descriptions. Eventually, using a title tag checker can assist ensure that a website is effectively maximized for search engine rankings which it is as successful as feasible in organic search results.
                            </p>


                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default TitleTag
