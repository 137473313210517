import React from 'react'
import { Link } from 'react-router-dom';
// import Sidebarads from './Sidebarads';

const PrivacyPolicy = () => {
    return (
        <>
            <section className="py-5">

                <div className="container px-5 my-5">
                    <div className="row">
                        <div className="col-12">
                            <Link to="/" className="btn btn-outline-dark"><i className="bi bi-arrow-left me-2"></i>Back</Link>
                        </div>
                    </div>
                    <div className="text-center mb-5">
                        <h1 className="fw-bolder">Privacy Policy</h1>

                    </div>
                    <div className="row gx-5">
                        <div className="col-xl-1"></div>
                        <div className="col-xl-10">


                            <h4 className="fw-bolder">Privacy Policy for Webrizer</h4>
                            <hr />
                            <div className="row">
                                <div className="col-12">
                                    <div className="alert alert-success" role="alert">
                                        <p>The Webrizer Tech, easily accessible from https://webrizer.com, one of our major priorities is the privacy of our visitors. This privacy policy document consists of types of information that is gathered & also tape-recorded by Webrizer Tech & also how we use it.If you have added questions or need even more information about our Privacy Policy, do not think twice to contact us on e-mail (sandy.avhale143@gmail.com). This Privacy Policy uses only to our on-line activities & also is valid for visitors to our website when it come to the information that they shared and/or gather in Webrizer Tech. This policy is not appropriate to any type of information collected offline or using channels apart from this website. Our Privacy Policy was produced with the help of the Free Privacy Policy Generator.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row ">
                                <div className="col-12">

                                    <div className="alert alert-info mb-4" role="alert">
                                        <h4 className="alert-heading">Information we collect</h4>
                                        <p>The individual information that you are asked to offer, & the reasons you are asked to provide it, will certainly be explained to you at the point we ask you to offer your individual information.If you contact us straight, we may obtain additional information concerning you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, & any kind of various other information you may select to provide</p>
                                    </div>

                                    <div className="alert alert-warning mb-4" role="alert">
                                        <h4 className="alert-heading">How we use your information</h4>
                                        <p>We use the information we accumulate in numerous ways, consisting of to:</p>
                                        <ul>
                                            <li>Provide, run, as well as keep our website</li>


                                            <li>Boost, personalize, & broaden our website</li>
                                            <li>Understand & also evaluate how you utilize our website</li>
                                            <li>Develop brand-new products, services, features, as well as functionality</li>
                                            <li>Communicate with you, either directly or through one of our partners, consisting of for customer service, to offer you with updates as well as various other information connecting to the website, & also for marketing & advertising purposes</li>
                                            <li>Send you emails</li>
                                            <li>Discover & also prevent fraud.</li>

                                        </ul>
                                    </div>

                                    <div className="alert alert-info mb-4" role="alert">
                                        <h4 className="alert-heading">Google DoubleClick DART Cookie</h4>
                                        <p>Google is among a third-party vendor on our site. It additionally uses cookies, referred to as DART cookies, to offer ads to our site visitors based upon their visit to www.website.com as well as other sites on the internet. However, visitors may choose to decrease making use of DART cookies by seeing the Google advertisement & also content network Privacy Policy at the following URL-https://policies.google.com/technologies/ads</p>
                                    </div>

                                    <div className="alert alert-warning mb-4" role="alert">
                                        <h4 className="alert-heading">Advertising Partners Privacy Policies</h4>
                                        <p>You can get in touch list to find the privacy policy for each & every of the advertising partners of webrizer Tech. Third-party ads servers, ads networks takes advantage of technologies like cookies, JavaScript, or Web Becomes that are utilized of in their particular advertisements & additionally links that appear on webrizer Tech, which are sent straight to users browser. They right away receive your IP address when this takes place. These technologies are used to boost the effectiveness of their advertising campaigns to tailor the advertising content that you see on websites that you have a look at. Bear in mind that webrizer Tech has no access to or control over these cookies that are used by third-party advertisers.</p>

                                    </div>

                                    <div className="alert alert-info mb-4" role="alert">
                                        <h4 className="alert-heading">Third Party Privacy Policies</h4>
                                        <p>Webrizer Tech's Privacy Policy does not apply to other advertisers or websites. hence, we are advising you to consult the respective privacy policies of these third-party ads servers for more specific information. It may include their practices and instructions about how to opt-out of certain options. You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.</p>
                                    </div>

                                    <div className="alert alert-warning mb-4" role="alert">
                                        <h4 className="alert-heading">CCPA Privacy Rights (Do Not Sell My Personal Information)</h4>
                                        <p>Under the CCPA, among other rights, California consumers have the right to:

                                            Request that a business that accumulates a customer's individual information reveal the categories & also particular pieces of individual information that a business has collected concerning customers. Request that a business delete any individual data about the customer that a business has collected.Request that a business that offers a customer's individual information, not sell the customer's individual information. If you make a request, we have month's to react to you. If you want to use any of these copyrights then, please contact to us.</p>

                                    </div>



                                    <div className="alert alert-info mb-4" role="alert">
                                        <h4 className="alert-heading">GDPR Data Protection Rights</h4>
                                        <p>We want to make sure you are completely knowledgeable about every one of your data protection rights. Every user is qualified to the following:</p>

                                        <ul>
                                            <li>The right to gain access to- You deserve to request copies of your individual data. We may charge you a tiny fee for this service.</li>

                                            <li>The right to rectification- You can request that we remedy any information you think is inaccurate. You additionally deserve to request that we finish the information you think is insufficient.</li>

                                            <li>The right to erasure- You deserve to request that we erase your individual data, under particular conditions.</li>

                                            <li>The right to restrict processing- You deserve to request that we limit the processing of your personal data, under specific conditions.</li>

                                            <li>The right to challenge processing- You have the right to challenge our processing of your individual data, under specific conditions.</li>

                                            <li>The right to data portability- You can request that we move the data that we have accumulated to another organization, or straight to you, under specific conditions.</li>
                                        </ul>

                                    </div>

                                    <div className="alert alert-warning mb-4" role="alert">
                                        <h4 className="alert-heading">Children's Information</h4>
                                        <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity. webrizer Tech does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly motivate you to call us quickly & also we will certainly do our best shots to promptly get rid of such information from our records.</p>

                                    </div>

                                </div>
                            </div>




                        </div>

                        <div className="col-xl-1">
                            {/* <Sidebarads /> */}
                        </div>


                    </div>
                </div>
            </section>

        </>
    )
}

export default PrivacyPolicy
