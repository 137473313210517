import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getmdTag } from "../../redux/features/onPageSlice";
import { Link } from 'react-router-dom';
// import Sidebarads from '../layout/Sidebarads';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Errorshow from '../layout/Errorshow';
import Loader from '../layout/Loader';

const MdTag = () => {

    const dispatch = useDispatch();
    // const [myUrl, SetMyUrl] = useState();
    const { errorCode, resone, result } = useSelector((state) => state.OnPagAPI.mdtag)
    const loading = useSelector((state) => state.OnPagAPI.isLoading)
    const error = useSelector((state) => state.OnPagAPI.Error)
    const website = useSelector((state) => state.OnPagAPI.website)
    // console.log(errorCode)
    // console.log(result)

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: {
            website: website,
        },
        validationSchema: Yup.object({
            website: Yup.string().url().required('Please Enter Valid Website URL'),
        }),
        onSubmit: (data) => {
            //console.warn('Form Submited', data);
            // let url = data.website.split("/");
            // SetMyUrl(url[0] + "//" + url[2])
            dispatch(getmdTag(data));
        }
    })

    return (
        <>
            <section className="py-5">

                <div className="container px-5 my-5">
                    <div className="row">
                        <div className="col-12">
                            <Link to="/" className="btn btn-outline-dark"><i className="bi bi-arrow-left me-2"></i>Back</Link>
                        </div>
                    </div>
                    <div className="text-center mb-5">
                        <h1 className="fw-bolder">Meta Description Checker</h1>
                        <p className="lead  text-muted mb-0">Meta description checker is a tool that helps you to examine if your website has a meta description It can help you to determine any kind of errors/bugs/warnings in the meta description & likewise make certain that it is optimized for search engine optimization. It is a necessary tool for website owners to assure that their website is correctly enhanced for online search engine visibility.</p>
                    </div>
                    <div className="row gx-5">
                        <div className="col-xl-1"></div>
                        <div className="col-xl-10">

                            <div className="row">
                                {/* <div className="col-12 "> px-5 */}
                                <div className="col-12 ">
                                    <form onSubmit={handleSubmit} >
                                        <label className='form-label'><b>Enter Website URL*</b></label>
                                        <div className="input-group mb-4">
                                            <input type="text"
                                                id="website"
                                                name="website"
                                                className="form-control"
                                                placeholder="Website Url"
                                                aria-label="Recipient's username"
                                                onChange={handleChange}
                                                value={values.name}
                                                onBlur={handleBlur}
                                                aria-describedby="button-addon2" />

                                            <button className="btn btn-lg btn-dark" type="submit" id="button-addon2">Check</button>
                                        </div>

                                        {(errors.website && touched.website) ? <div className="alert alert-danger mb-4" role="alert">{errors.website}</div> : ""}
                                    </form>
                                </div>
                            </div>
                            <hr />


                            {loading ?
                                <Loader />
                                : ""}


                            {error && errorCode !== 200 ?
                                <Errorshow error={error} />
                                : ""}


                            {errorCode === 200 ?

                                <div className="row">
                                    <div className="col-12">
                                        <h5>Result : </h5>
                                        <div className={`alert alert-${result.alert}`} role="alert">
                                            <h4 className="alert-heading">Website : {website}</h4>

                                            <p className="fs-5"><span className="mb-0 badge bg-secondary fs-6">Meta Description : </span> {result.description}</p>

                                            <p className="mt-2">{result.alertmsg}</p>

                                            <p className="mb-0 badge bg-secondary fs-6">Length : {result.pageLength}</p>
                                        </div>
                                    </div>
                                </div>

                                : ""}

                            {errorCode === 400 ?

                                <div className="row">
                                    <div className="col-12">
                                        <div className={`alert alert-${result.alert}`} role="alert">
                                            <h4 className="alert-heading">{resone}</h4>
                                        </div>
                                    </div>
                                </div>

                                : ""}

                            <div className="row ">
                                <div className="col-12">

                                    <div className="alert alert-info mb-4" role="alert">
                                        <h4 className="alert-heading">What it is</h4>
                                        <p>Meta Description is one more essential HTML element that explains extra descriptively to Search Engines what your page is about. Meta Descriptions are frequently utilized as the text snippets used in Online search engine results (though Search Engines are inceasingly producing these themselves) as well as can help further signal to Search Engines what keywords your page need to rank for.</p>
                                    </div>

                                    <div className="alert alert-warning mb-4" role="alert">
                                        <h4 className="alert-heading">How to fix it</h4>
                                        <p>Make sure your page has a Meta Description included of & also goes to an optimal length (between 70 & 160 characters). Make your Meta Description text interesting & easy to understand. Usage phrases & also keywords relevant to the page & user that you would love to rank for. Meta Description is normally available to be updated in your CMS.</p>
                                    </div>
                                </div>
                            </div>




                        </div>

                        <div className="col-xl-1">
                            {/* <Sidebarads /> */}
                        </div>


                    </div>

                    <center><h2 className="fw-bolder text-clr mb-5">Why Meta Description Checker Tool? </h2></center>
                    <div className="row gx-5">
                        <div className="col-lg-12 lead text-muted text-justify">

                            <p>
                                Meta Description Checker Tool is an exceptionally handy tool for any sort of website. It is a tool that can examine the meta descriptions of any kind of webpage in addition to give insight into exactly how effectively the meta description is developed. Meta descriptions are necessary for SEO, as they are simply among one of the most crucial aspects of a website in terms of search engine optimization.
                            </p>
                            <p>
                                A meta description checker tool can examine the length, readability, relevance, & keyword optimization of a meta description. It can additionally see to it that the meta description fits within the guidelines of search engine optimization standards. The tool can be taken advantage of to find out which words are being used too often, or to discover if a keyword is being made use of mostly. The tool can also be made use of to make sure that the meta description is in line with the content on the website page
                            </p>
                            <p>
                                The meta description checker tool is in addition useful for searching for duplicate meta descriptions. Duplicate meta descriptions can show up in search engine results & additionally can puzzle search engine crawlers. The tool can find any kind of instances of duplicate meta descriptions & can aid to make certain that the page is just making use of one meta description.
                            </p>
                            <p>
                                The meta description checker tool is furthermore practical for searching for busted meta descriptions. Damaged meta descriptions can occur when the meta description has characters or symbols that are not sustained by the search engine. The tool can situate any type of damaged meta descriptions & can assist to guarantee that the page is simply taking advantage of reputable meta descriptions.
                            </p>
                            <p>
                                The meta description checker tool is additionally advantageous for trying to find outdated meta descriptions. Out-of-date meta descriptions can occur when the meta description is not kept current with the content on the page. The tool can locate any type of out-of-date meta descriptions in addition to can help to ensure that the page is just utilizing existing meta descriptions.
                            </p>
                            <p>
                                On the whole, the meta description checker tool is a fantastic tool for any kind of website. It can assist to make certain that the meta description is appropriately taken full advantage of for search engine optimization & is current with the content on the page. The tool is additionally useful for looking for duplicate, broken, & additionally outdated meta descriptions. The meta description checker tool can be an excellent property for any kind of type of website.

                            </p>


                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default MdTag

