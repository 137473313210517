import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getLang } from "../../redux/features/onPageSlice";
import { Link } from 'react-router-dom';
// import Sidebarads from '../layout/Sidebarads';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Errorshow from '../layout/Errorshow';
import Loader from '../layout/Loader';

const PageLang = () => {

    const dispatch = useDispatch();
    // const [myUrl, SetMyUrl] = useState();
    const { errorCode, resone, result } = useSelector((state) => state.OnPagAPI.plang)
    const loading = useSelector((state) => state.OnPagAPI.isLoading)
    const error = useSelector((state) => state.OnPagAPI.Error)
    const website = useSelector((state) => state.OnPagAPI.website)
    // console.log(errorCode)
    // console.log(result)

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: {
            website: website,
        },
        validationSchema: Yup.object({
            website: Yup.string().url().required('Please Enter Valid Website URL'),
        }),
        onSubmit: (data) => {
            //console.warn('Form Submited', data);
            // let url = data.website.split("/");
            // SetMyUrl(url[0] + "//" + url[2])
            dispatch(getLang(data));
        }
    })

    return (
        <>
            <section className="py-5">

                <div className="container px-5 my-5">
                    <div className="row">
                        <div className="col-12">
                            <Link to="/" className="btn btn-outline-dark"><i className="bi bi-arrow-left me-2"></i>Back</Link>
                        </div>
                    </div>
                    <div className="text-center mb-5">
                        <h1 className="fw-bolder">HTML Attribute Language Checker</h1>
                        <p className="lead  text-muted mb-0">The HTML Attribute Language Checker Tool is a free of charge online device that can be utilized to check out the validity of HTML code.</p>
                    </div>
                    <div className="row gx-5">
                    <div className="col-xl-1"></div>
                        <div className="col-xl-10">

                            <div className="row">
                                {/* <div className="col-12 "> px-5 */}
                                <div className="col-12 ">
                                    <form onSubmit={handleSubmit} >
                                        <label className='form-label'><b>Enter Website URL*</b></label>
                                        <div className="input-group mb-4">
                                            <input type="text"
                                                id="website"
                                                name="website"
                                                className="form-control"
                                                placeholder="Website Url"
                                                aria-label="Recipient's username"
                                                onChange={handleChange}
                                                value={values.name}
                                                onBlur={handleBlur}
                                                aria-describedby="button-addon2" />

                                            <button className="btn btn-lg btn-dark" type="submit" id="button-addon2">Check</button>
                                        </div>

                                        {(errors.website && touched.website) ? <div className="alert alert-danger mb-4" role="alert">{errors.website}</div> : ""}
                                    </form>
                                </div>
                            </div>
                            <hr />


                            {loading ?
                                <Loader />
                                : ""}


                            {error && errorCode !== 200 ?
                                <Errorshow error={error} />
                                : ""}


                            {errorCode === 200 ?

                                <div className="row">
                                    <div className="col-12">
                                        <h5>Result : </h5>
                                        <div className={`alert alert-${result.alert}`} role="alert">
                                            <h4 className="alert-heading">Website : {website}</h4>

                                            <p>{result.langCodeAlert} {result.langCode ? <b className="mb-0 badge bg-secondary fs-6">{result.langCode}</b> : ""}</p>

                                            <p className="mt-2">{result.alertmsg}</p>


                                        </div>
                                    </div>
                                </div>

                                : ""}

                            {errorCode === 400 ?

                                <div className="row">
                                    <div className="col-12">
                                        <div className={`alert alert-${result.alert}`} role="alert">
                                            <h4 className="alert-heading">{resone}</h4>
                                        </div>
                                    </div>
                                </div>

                                : ""}

                            <div className="row ">
                                <div className="col-12">

                                    <div className="alert alert-info mb-4" role="alert">
                                        <h4 className="alert-heading">What it is</h4>
                                        <p>The lang attribute is made use of to define the intended language of the current page to user's browsers as well as Search Engines. Search Engines may make use of the lang attribute to return language specific search results to a searcher, & in the browser, lang attribute can signal the need to change to a various language if it is different to the user's very own recommended language.</p>
                                    </div>

                                    <div className="alert alert-warning mb-4" role="alert">
                                        <h4 className="alert-heading">How to fix it</h4>
                                        <p>We advise adding the lang attribute to the HTML tag of every page to prevent any chance of misinterpretation of language. This may need to be by hand added to the site's HTML code, or might be regulated by your CMS.</p>
                                    </div>
                                </div>
                            </div>




                        </div>

                        <div className="col-xl-1">
                            {/* <Sidebarads /> */}
                        </div>


                    </div>

                    <center><h2 className="fw-bolder text-clr mb-5">Why HTML Attribute Language Checker Tool? </h2></center>
                    <div className="row gx-5">
                        <div className="col-lg-12 lead text-muted text-justify">

                            <p>
                                HTML Characteristic Language Checker Tool is a powerful tool that helps web developers & also designers to rapidly as well as quickly check the validity of their HTML code.This tool is particularly useful for those who are new to HTML & need to ensure that their code is valid & also as much as day.
                            </p>
                            <p>
                                The HTML Quality Language Checker Tool is a totally free online tool that can be used to check the validity of HTML code. It is created to help web developers as well as designers to swiftly as well as easily inspect the validity of their HTML code. The tool can be made use of to check the syntax of HTML tags, attributes, & also values, in addition to look for any errors in the code.
                            </p>
                            <p>
                                The HTML Feature Language Checker Tool is an excellent tool for web developers & designers who are brand-new to HTML as well as require to ensure that their code is valid & approximately day. The tool can be utilized to rapidly & also quickly inspect the validity of HTML code, as well as it can likewise be utilized to check for any kind of errors in the code. The tool is additionally valuable for experienced web developers & also designers who need to promptly check the validity of their HTML code.
                            </p>
                            <p>
                                The HTML Attribute Language Checker Tool is a wonderful tool for web developers & also designers who require to quickly & also easily check the validity of their HTML code. The tool is free to make use of as well as can be made use of to quickly & also conveniently check the validity of HTML code. It is a fantastic tool for those that are new to HTML & require to ensure that their code stands & approximately date.
                            </p>


                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default PageLang
