import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getKeyword } from "../../redux/features/onPageSlice";
import { Link } from 'react-router-dom';
// import Sidebarads from '../layout/Sidebarads';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Errorshow from '../layout/Errorshow';
import Loader from '../layout/Loader';

const Mkeyword = () => {

    const dispatch = useDispatch();
    //const [myUrl, SetMyUrl] = useState();
    const { errorCode, resone, result } = useSelector((state) => state.OnPagAPI.keyword)
    const loading = useSelector((state) => state.OnPagAPI.isLoading)
    const error = useSelector((state) => state.OnPagAPI.Error)
    const website = useSelector((state) => state.OnPagAPI.website)
    // console.log(errorCode)
    // console.log(result)

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: {
            website: website,
        },
        validationSchema: Yup.object({
            website: Yup.string().url().required('Please Enter Valid Website URL'),


        }),
        onSubmit: (data) => {
            //console.warn('Form Submited', data);
            //let url = data.website.split("/");
            //SetMyUrl(url[0] + "//" + url[2])
            dispatch(getKeyword(data));
        }
    })

    return (
        <>
            <section className="py-5">

                <div className="container px-5 my-5">
                    <div className="row">
                        <div className="col-12">
                            <Link to="/" className="btn btn-outline-dark"><i className="bi bi-arrow-left me-2"></i>Back</Link>
                        </div>
                    </div>
                    <div className="text-center mb-5">
                        <h1 className="fw-bolder">Meta Keyword Checker</h1>
                        <p className="lead  text-muted mb-0">The meta key phrase is a crucial HTML element that lets users in addition to search engines know what the topic of the webpage is & also what keywords the page needs to rate for. In a user's browser, the search phrase will certainly appear in the Header Bar. The keyword density is an excellent On-Page SEO variable (as well as among the easiest to improve).</p>
                    </div>
                    <div className="row gx-5">
                        <div className="col-xl-1"></div>
                        <div className="col-xl-10">

                            <div className="row">
                                {/* <div className="col-12 "> px-5 */}
                                <div className="col-12 ">
                                    <form onSubmit={handleSubmit} >
                                        <label className='form-label'><b>Enter Website URL*</b></label>
                                        <div className="input-group mb-4">
                                            <input type="text"
                                                id="website"
                                                name="website"
                                                className="form-control"
                                                placeholder="Website Url"
                                                aria-label="Recipient's username"
                                                onChange={handleChange}
                                                value={values.name}
                                                onBlur={handleBlur}
                                                aria-describedby="button-addon2" />

                                            <button className="btn btn-lg btn-dark" type="submit" id="button-addon2">Check</button>
                                        </div>

                                        {(errors.website && touched.website) ? <div className="alert alert-danger mb-4" role="alert">{errors.website}</div> : ""}
                                    </form>
                                </div>
                            </div>
                            <hr />


                            {loading ?
                                <Loader />
                                : ""}


                            {error && errorCode !== 200 ?
                                <Errorshow error={error} />
                                : ""}


                            {errorCode === 200 ?

                                <div className="row">
                                    <div className="col-12">
                                        <h5>Result : </h5>
                                        <div className={`alert alert-${result.alert}`} role="alert">
                                            <h4 className="alert-heading">Website : {website}</h4>

                                            <p className="fs-5"><span className="mb-0 badge bg-secondary fs-6">Keywords : </span> {result.keywords}</p>

                                            <p className="mt-2">{result.alertmsg}</p>

                                        </div>
                                    </div>
                                </div>

                                : ""}

                            {errorCode === 400 ?

                                <div className="row">
                                    <div className="col-12">
                                        <div className={`alert alert-${result.alert}`} role="alert">
                                            <h4 className="alert-heading">{resone}</h4>
                                        </div>
                                    </div>
                                </div>

                                : ""}

                            <div className="row ">
                                <div className="col-12">

                                    <div className="alert alert-info mb-4" role="alert">
                                        <h4 className="alert-heading">What it is</h4>
                                        <p>Meta keywords checker is a tool taken advantage of to take a look at the keywords utilized in a website's meta tags. It assists to identify which keywords are being utilized along with just how frequently they are made use of. It can additionally assist to acknowledge any kind of type of potential keyword stuffing issues. This tool can be used to optimize a website's SEO & guarantee that the ideal keywords are being made use of.</p>
                                    </div>

                                    <div className="alert alert-warning mb-4" role="alert">
                                        <h4 className="alert-heading">How to fix it</h4>
                                        <ul>
                                            <li>1. Start by researching the best keywords for your website.</li>
                                            <li>2. Use a keyword research tool to identify the most relevant keywords.</li>
                                            <li>3. Create a list of keywords that you want to use in your meta tags.</li>
                                            <li>4. Use a meta keywords checker to ensure that the keywords you have chosen are valid.</li>
                                            <li>5. Make sure that the keywords you have chosen are relevant to the content of your website.</li>
                                            <li>6. Check the keyword density of your meta tags to ensure that they are not too high or too low.</li>
                                            <li>7. Adjust the keywords if necessary to ensure that they are optimized for search engine rankings.</li>
                                            <li>8. Finally, save the changes and check the meta keywords checker again to make sure that everything is correct.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>




                        </div>

                        <div className="col-xl-1">
                            {/* <Sidebarads /> */}
                        </div>


                    </div>

                    <center><h2 className="fw-bolder text-clr mb-5">Why Meta keyword Checker Tool? </h2></center>
                    <div className="row gx-5">
                        <div className="col-lg-12 lead text-muted text-justify">

                            <p>
                                Meta key words expression checkers are a helpful tool for analyzing the performance of a website's search engine optimization (Seo) initiatives. They allow site owners to see especially simply specifically how their website is being indexed by search engines along with make educated choices associating with which essential expressions need to be included or gotten rid of from their web site in order to aid it ranking better in online internet search engine results.
                            </p>
                            <p>
                                A meta key phrase mosaic works by examining the web content of a web website & later comparing it with the key phrases that are being made use of by net internet search engine to find as well as ranking internet sites. This enables website proprietors to see which keywords are being made use of to index their website & likewise to see which keyword phrases are being made use of to rate it. By identifying the essential expressions that are being taken advantage of to rate a web site, site proprietors are much better able to produce product that is boosted for web internet search engine outcomes.
                            </p>
                            <p>
                                Making use of a meta key words mosaic, website proprietors can contrast their web website's web material against keyword phrases that are being utilized by internet online search engine. This assists them to identify which keyword expressions are being taken advantage of to index their internet site along with which keyword phrases are being used to price it. By understanding which keyword phrases are being utilized to index their web website, web website owners are far better able to generate material that is taken full advantage of for internet search engine end results.
                            </p>
                            <p>
                                In addition to aiding site owners identify which keyword expressions are being made use of to index their net site, meta key words checkers can additionally assist site proprietors recognize which keyword expressions are being made use of to rate their web site. This is extremely important due to the fact that different keyword expressions can have various search engine outcomes. As an instance, the keyword phrase "red footwear" might have various online search engine rankings than the key phrase expression "black footwear." By comprehending which keyword expressions are being utilized to put a website, web site proprietors can develop internet content that is boosted for those specific expressions.
                            </p>
                            <p>
                                Meta crucial expression checkers can additionally aid web website proprietors to acknowledge which sites are linking to their internet site. This is extremely important considering that online search engine formulas take advantage of links to identify the importance of a website to a search concern. By recognizing which web sites are linking to a website, internet site owners can develop internet content that is enhanced for those internet websites.
                            </p>
                            <p>
                                Inevitably, meta keywords checkers can in addition assist internet website proprietors to recognize which sites are linking to their internet site as well as figuring out which keyword expressions are being made use of to position those web sites. This is vital because numerous keyword phrases can have different online internet search engine positions. By understanding which keyword expressions are being made use of to position a web website, website proprietors can generate web material that is maximized for those phrases.
                            </p>
                            <p>
                                Meta crucial expression checkers are a crucial tool for assessing the efficiency of a web website's seo initiatives. They permit site owners to recognize which key words are being utilized to index their web website, which keyword expressions are being utilized to place their web website, in addition to which web sites are connecting to their website. This enables site owners to establish web content that is improved for web search engine outcomes.
                            </p>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


export default Mkeyword
