import React from 'react'
import { Link } from 'react-router-dom';
import headerImg from '../../image/webrizer.png';
import AnchorLink from 'react-anchor-link-smooth-scroll'
// import Sidebarads from './Sidebarads';



const Home = () => {
    return (
        <div>

            <header className="bg-dark py-5 cs-header d-flex justify-content-center align-items-center">
                <div className="container h-100vh ">
                    <div className="row gx-5 align-items-center justify-content-center">
                        <div className="col-lg-8 col-xl-6 col-xxl-6">
                            <div className="my-5 text-center text-xl-start">
                                <h1 className="display-4 fw-bolder text-white mb-2">Get Your Instantly Actionable FREE SEO Audit In Less Than Seconds</h1>
                                <p className="lead  text-white-50 mb-4">Receive a quick & workable SEO audit of your website completely free in simply secs.</p>
                                <div className="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xl-start">
                                    <AnchorLink className="btn btn-primary btn-lg px-4 me-sm-3" href="#features" smooth="true" >Get Started</AnchorLink>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-xxl-6 d-none d-xl-block text-center"><img className="img-fluid rounded-3 " src={headerImg} alt="..." /></div>
                    </div>
                </div>
            </header>


            <section className="py-5" id="features">
                <div className="container  my-5">
                    <center><h5 className="text-clr">Tons of great features</h5></center>
                    <center><h2 className="fw-bolder mb-5">A better way to check website vulnerability.</h2></center>

                    <center><h2 className="fw-bolder text-clr mb-5" >On-Page SEO Tools</h2></center>
                    <div className="row gx-5">
                        <div className="col-lg-1">
                            {/* <Sidebarads /> */}
                        </div>

                        <div className="col-lg-10">
                            <div className="row gx-5 row-cols-1 row-cols-md-2">

                                <div className="col-md-4">

                                    <div className="row">
                                        <div className="col-12 mb-5 h-100">
                                            <Link to="title-tag-checker" className="btn-card">
                                                <div className="card card-link">
                                                    <div className="card-body">
                                                        <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i className="bi bi-card-text"></i></div>
                                                        <h2 className="h5">Title Tag Checker</h2>
                                                        <p className="mb-0 text-justify">Title Tag Checker is a terrific tool to make sure that your website to inspect the accuracy as well as relevance of title tags on website webpage </p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="col-12 mb-5 h-100">
                                            <Link to="canonical-url-checker" className="btn-card">
                                                <div className="card card-link">
                                                    <div className="card-body">
                                                        <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i className="bi bi-code-slash"></i></div>
                                                        <h2 className="h5">Canonical URL Checker</h2>
                                                        <p className="mb-0 text-justify">A Canonical URL Checker is a tool that verifies if the canonical URL of a page is correct or not.</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>

                                        <div className="col-12 mb-5 h-100">
                                            <Link to="html-attribute-lang-tag-checker" className="btn-card">
                                                <div className="card card-link">
                                                    <div className="card-body">
                                                        <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i className="bi bi-file-text"></i></div>
                                                        <h2 className="h5">HTML Attribute Lang Checker</h2>
                                                        <p className="mb-0">It's Tool is a powerful tool that helps web developers and designers to quickly and easily check the validity of their HTML code.</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>


                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="row">
                                        <div className="col-12 mb-5  h-100">
                                            <Link to="meta-description-checker" className="btn-card">
                                                <div className="card card-link">
                                                    <div className="card-body">
                                                        <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i className="bi bi-file-earmark-word"></i></div>
                                                        <h2 className="h5">Meta Description</h2>
                                                        <p className="mb-0 ">Meta Description checker is a crucial tool for website to ensure their meta descriptions are optimized for SE visibility</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>


                                        <div className="col-12 mb-5 h-100">
                                            <Link to="h1-tag-checker" className="btn-card">
                                                <div className="card card-link">
                                                    <div className="card-body">
                                                        <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i className="bi bi-type-h1"></i></div>
                                                        <h2 className="h5">H1 Tag Checker</h2>
                                                        <p className="mb-0 ">A Comprehensive Tool for Optimizing Headings. An Essential Tool for SEO Professionals.</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>

                                        <div className="col-12 mb-5 h-100">
                                            <Link to="robots-txt-checker" className="btn-card">
                                                <div className="card card-link">
                                                    <div className="card-body">
                                                        <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-filetype-txt" viewBox="0 0 16 16">
                                                                <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-2v-1h2a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.928 15.849v-3.337h1.136v-.662H0v.662h1.134v3.337h.794Zm4.689-3.999h-.894L4.9 13.289h-.035l-.832-1.439h-.932l1.228 1.983-1.24 2.016h.862l.853-1.415h.035l.85 1.415h.907l-1.253-1.992 1.274-2.007Zm1.93.662v3.337h-.794v-3.337H6.619v-.662h3.064v.662H8.546Z" />
                                                            </svg></div>
                                                        <h2 className="h5">Robots.txt Checker</h2>
                                                        <p className="mb-0 ">Robots.txt Checker With SEO title suggestion is an effective tool that helps website owners and SEO professionals to promptly check the robots.txt file</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-4">
                                    <div className="row">
                                        <div className="col-12 mb-5  h-100">
                                            <Link to="meta-keyword-checker" className="btn-card">
                                                <div className="card card-link">
                                                    <div className="card-body">
                                                        <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i className="bi bi-file-earmark-text"></i></div>
                                                        <h2 className="h5">Meta Keyword Checker</h2>
                                                        <p className="mb-0">Meta keyword Checker is a terrific tool to make sure that your website is enhanceed for online search engine visibility.</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="col-12 mb-5 h-100">
                                            <Link to="header-tag-checker" className="btn-card">
                                                <div className="card card-link">
                                                    <div className="card-body">
                                                        <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i className="bi bi-card-heading"></i></div>
                                                        <h2 className="h5">Header Tag Checker</h2>
                                                        <p className="mb-0">The H2-H6 Header Tags are an important way of organizing the content of your website page</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>

                                        <div className="col-12 mb-5 h-100">
                                            <Link to="xml-sitemap-checker" className="btn-card">
                                                <div className="card card-link">
                                                    <div className="card-body">
                                                        <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-filetype-xml" viewBox="0 0 16 16">
                                                                <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM3.527 11.85h-.893l-.823 1.439h-.036L.943 11.85H.012l1.227 1.983L0 15.85h.861l.853-1.415h.035l.85 1.415h.908l-1.254-1.992 1.274-2.007Zm.954 3.999v-2.66h.038l.952 2.159h.516l.946-2.16h.038v2.661h.715V11.85h-.8l-1.14 2.596h-.025L4.58 11.85h-.806v3.999h.706Zm4.71-.674h1.696v.674H8.4V11.85h.791v3.325Z" />
                                                            </svg>
                                                        </div>
                                                        <h2 className="h5">XML Sitemap Checker</h2>
                                                        <p className="mb-0">It's a tool that is made use of to verify the integrity of an XML Sitemap file.It assists to make certain that the information contained in the Sitemap is exact</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-1">
                            {/* <Sidebarads /> */}
                        </div>
                    </div>



                    <center><h2 className="fw-bolder text-clr mb-5  mt-5s">Why On-page SEO? </h2></center>
                    <div className="row gx-5">
                        <div className="col-lg-12 lead text-muted">
                            <p>
                                On-page SEO tools are needful for site owners as well as marketers who are focus to boost their content for search engines. On-page SEO tools provide insight right into just how search engine spiders view a website, including the structure of the site, the content, & also the internal & external links. Making use of these tools can help website owners boost their search engine visibility.
                            </p>
                            <p>

                                One of the most common on-page SEO tools are keyword research tools & also page analysis tools. Keyword research tools assist to identify the most effective keywords to include in website content. These keywords need to be relevant to the topic of the website & ought to be utilized in the content & also the title tags. Page analysis tools give insight into the structure of the website, such as the HTML code & the variety of words on the page. They can likewise determine errors on the website that might be punished by search engines.
                            </p>
                            <p>
                                Other on-page SEO tools include link analysis tools & URL optimization tools. Link analysis tools recognize the quality & also quantity of links pointing to a website, as well as any kind of issues with the link structure. URL optimization tools assist to make sure that the URLs are enhanced for search engine rankings.
                            </p>
                            <p>
                                On-page SEO is an part of website enhancement & also using on-page SEO tools is crucial for site owners as well as marketers who are seeking to boost their search engine rankings. Use of these tools can help site owners recognize bugs and boost their site for superior visibility in search engine rankings.
                            </p>

                        </div>
                    </div>


                    <center><h2 className="fw-bolder text-clr mb-5 mt-5">Why SEO is important for website? </h2></center>
                    <div className="row gx-5">
                        <div className="col-lg-12 lead text-muted">
                            <p>
                            SEO is a crucial part of website improvement & as it helps to make sure that your website is visible to customers when they search for keywords. SEO entails maximizing your website's content as well as structure to make it simpler for search engines to crawl, index, & recognize what your website has to do with. When done properly, SEO can assistance your website to ranking in online search engine results pages for pertinent keywords and phrases, bringing even more traffic & potential customers to your site.
                            </p>
                            <p>
                            SEO is very important for website, as it enables them to compete in a crowded marketplace by increasing their visibility online. It can help to ensure that their website shows up above their competitors in online search engine results, giving them a lot more visibility & also a better chance of transforming possible customers right into actual customers.
                            </p>
                            <p>
                            SEO can likewise aid to increase the quality of traffic concerning your website, as more targeted as well as natural traffic is more probable to cause greater conversion rates. SEO can additionally enhance the overall user experience by making sure that the website is optimized for all devices & supplies users with a favorable experience.

                            </p>
                            <p>
                            Ultimately, SEO is needful for website owners as it can aid to build trust as well as credibility with search engines. By enhancing the right keywords & also phrases, website owners can show their expertise as well as authority in their field, causing greater rankings & more natural traffic.
                            </p>
                            <p>
                            Particularly, SEO is a crucial part of website optimization, as it helps to boost visibility & also website traffic, improve client experience, & develop belief as well as reputation with search engines.
                            </p>




                        </div>
                    </div>

                </div>
            </section>

        </div>
    )
}

export default Home
