import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getTitle = createAsyncThunk('getTitle', async(body) =>{
    const responce = await fetch(`https://api.webrizer.com/get-title`, {
        method: 'POST',
        body: JSON.stringify({'url':body.website})
    })
    return await responce.json();
})

export const getmdTag = createAsyncThunk('getmdTag', async(body) =>{
    const responce = await fetch(`https://api.webrizer.com/get-desc`, {
        method: 'POST',
        body: JSON.stringify({'url':body.website})
    })
    return await responce.json();
})

export const getKeyword = createAsyncThunk('getKeyword', async(body) =>{
    const responce = await fetch(`https://api.webrizer.com/get-keyword`, {
        method: 'POST',
        body: JSON.stringify({'url':body.website})
    })
    return await responce.json();
})

export const getCanonical = createAsyncThunk('getCanonical', async(body) =>{
    const responce = await fetch(`https://api.webrizer.com/get-canonical`, {
        method: 'POST',
        body: JSON.stringify({'url':body.website})
    })
    return await responce.json();
})

export const getH1tag = createAsyncThunk('getH1tag', async(body) =>{
    const responce = await fetch(`https://api.webrizer.com/get-hone`, {
        method: 'POST',
        body: JSON.stringify({'url':body.website})
    })
    return await responce.json();
})

export const getHall = createAsyncThunk('getHall', async(body) =>{
    const responce = await fetch(`https://api.webrizer.com/get-headers`, {
        method: 'POST',
        body: JSON.stringify({'url':body.website})
    })
    return await responce.json();
})

export const getLang = createAsyncThunk('getLang', async(body) =>{
    const responce = await fetch(`https://api.webrizer.com/get-lang`, {
        method: 'POST',
        body: JSON.stringify({'url':body.website})
    })
    return await responce.json();
})

export const getRobot = createAsyncThunk('getRobot', async(body) =>{
    const responce = await fetch(`https://api.webrizer.com/get-robottxt`, {
        method: 'POST',
        body: JSON.stringify({'url':body.website})
    })
    return await responce.json();
})

export const getSitemap = createAsyncThunk('getSitemap', async(body) =>{
    const responce = await fetch(`https://api.webrizer.com/get-xmlchecker`, {
        method: 'POST',
        body: JSON.stringify({'url':body.website})
    })
    return await responce.json();
})




const onPageSlice = createSlice({
    name: "onPage",
    initialState: {
        title: [],
        mdtag: [],
        keyword: [],
        canonical: [],
        hone : [],
        hall : [],
        plang : [],
        robot : [],
        sitemap : [],
        isLoading: false,
        Error:"",
        website:"",
    },
    reducers: {
    },
    extraReducers: (builder) => {

        /////////////////////////////////// call gettitile api for title tag checker ///////////////////////////////////
        builder.addCase(getTitle.pending, (state, action) => {
            state.title = [];
            state.isLoading = true;
            state.Error = "";
        });

        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(getTitle.fulfilled, (state, action) => {
            state.title = action.payload;
            state.website = action.payload.domain;
            state.isLoading = false;
        });

        builder.addCase(getTitle.rejected, (state, action) => {
            state.title = [];
            state.isLoading = false;
            state.Error = "There is something wrong, please try again later";
        });


        /////////////////////////////////// call getmdtag api for meta data checker /////////////////////////////////////
        builder.addCase(getmdTag.pending, (state, action) => {
            state.mdtag = [];
            state.isLoading = true;
            state.Error = "";
        });

        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(getmdTag.fulfilled, (state, action) => {
            state.mdtag = action.payload;
            state.website = action.payload.domain;
            state.isLoading = false;
        });

        builder.addCase(getmdTag.rejected, (state, action) => {
            state.mdtag = [];
            state.isLoading = false;
            state.Error = "There is something wrong, please try again later";
        });

        /////////////////////////////////// call getKeyword api for meta keyword checker /////////////////////////////////////

        builder.addCase(getKeyword.pending, (state, action) => {
            state.keyword = [];
            state.isLoading = true;
            state.Error = "";
        });

        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(getKeyword.fulfilled, (state, action) => {
            state.keyword = action.payload;
            state.website = action.payload.domain;
            state.isLoading = false;
        });

        builder.addCase(getKeyword.rejected, (state, action) => {
            state.keyword = [];
            state.isLoading = false;
            state.Error = "There is something wrong, please try again later";
        });


        /////////////////////////////////// call getCanonical api for Canonical tag  checker /////////////////////////////////////

         builder.addCase(getCanonical.pending, (state, action) => {
            state.canonical = [];
            state.isLoading = true;
            state.Error = "";
        });

        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(getCanonical.fulfilled, (state, action) => {
            state.canonical = action.payload;
            state.website = action.payload.domain;
            state.isLoading = false;
        });

        builder.addCase(getCanonical.rejected, (state, action) => {
            state.canonical = [];
            state.isLoading = false;
            state.Error = "There is something wrong, please try again later";
        });


        /////////////////////////////////// call H1tags api for H1 tag  checker /////////////////////////////////////

        builder.addCase(getH1tag.pending, (state, action) => {
            state.hone = [];
            state.isLoading = true;
            state.Error = "";
        });

        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(getH1tag.fulfilled, (state, action) => {
            state.hone = action.payload;
            state.website = action.payload.domain;
            state.isLoading = false;
        });

        builder.addCase(getH1tag.rejected, (state, action) => {
            state.hone = [];
            state.isLoading = false;
            state.Error = "There is something wrong, please try again later";
        });


        /////////////////////////////////// call Htags api for Header tag checker /////////////////////////////////////

        builder.addCase(getHall.pending, (state, action) => {
            state.hall = [];
            state.isLoading = true;
            state.Error = "";
        });

        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(getHall.fulfilled, (state, action) => {
            state.hall = action.payload;
            state.website = action.payload.domain;
            state.isLoading = false;
        });

        builder.addCase(getHall.rejected, (state, action) => {
            state.hall = [];
            state.isLoading = false;
            state.Error = "There is something wrong, please try again later";
        });

        /////////////////////////////////// call getLang api for Page Lang checker /////////////////////////////////////

        builder.addCase(getLang.pending, (state, action) => {
            state.plang = [];
            state.isLoading = true;
            state.Error = "";
        });

        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(getLang.fulfilled, (state, action) => {
            state.plang = action.payload;
            state.website = action.payload.domain;
            state.isLoading = false;
        });

        builder.addCase(getLang.rejected, (state, action) => {
            state.plang = [];
            state.isLoading = false;
            state.Error = "There is something wrong, please try again later";
        });


        /////////////////////////////////// call getRobot api for Robots.txt checker /////////////////////////////////////

        builder.addCase(getRobot.pending, (state, action) => {
            state.robot = [];
            state.isLoading = true;
            state.Error = "";
        });

        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(getRobot.fulfilled, (state, action) => {
            state.robot = action.payload;
            state.website = action.payload.domain;
            state.isLoading = false;
        });

        builder.addCase(getRobot.rejected, (state, action) => {
            state.robot = [];
            state.isLoading = false;
            state.Error = "There is something wrong, please try again later";
        });

        /////////////////////////////////// call getSitemap api for Xml sitemap checker checker /////////////////////////////////////

        builder.addCase(getSitemap.pending, (state, action) => {
            state.sitemap = [];
            state.isLoading = true;
            state.Error = "";
        });

        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(getSitemap.fulfilled, (state, action) => {
            state.sitemap = action.payload;
            state.website = action.payload.domain;
            state.isLoading = false;
        });

        builder.addCase(getSitemap.rejected, (state, action) => {
            state.sitemap = [];
            state.isLoading = false;
            state.Error = "There is something wrong, please try again later";
        });
    


    },
});

export default onPageSlice.reducer;