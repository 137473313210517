
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './component/layout/Navbar';
import Home from './component/layout/Home';
import Footer from './component/layout/Footer';
import TitleTag from './component/onpage/TitleTag';
import PrivacyPolicy from './component/layout/PrivacyPolicy';
import MdTag from './component/onpage/MdTag';
import Mkeyword from './component/onpage/Mkeyword';
import CanonicalTag from './component/onpage/CanonicalTag';
import H1Tag from './component/onpage/H1Tag';
import HtagAll from './component/onpage/HtagAll';
import PageLang from './component/onpage/PageLang';
import Aboutus from './component/layout/Aboutus';
import Contactus from './component/layout/Contactus';
import Page404 from './component/layout/Page404';
import RobotsTxt from './component/onpage/RobotsTxt';
import Sitemap from './component/onpage/Sitemap';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Navbar />
        
          <Routes>
            <Route exact path="/" element={<Home/>} />
            <Route exact path="title-tag-checker" element={<TitleTag />} />
            <Route exact path="meta-description-checker" element={<MdTag />} />
            <Route exact path="meta-keyword-checker" element={<Mkeyword />} />
            <Route exact path="canonical-url-checker" element={<CanonicalTag />} />
            <Route exact path="h1-tag-checker" element={<H1Tag />} />
            <Route exact path="header-tag-checker" element={<HtagAll />} />
            <Route exact path="html-attribute-lang-tag-checker" element={<PageLang />} />
            <Route exact path="robots-txt-checker" element={<RobotsTxt />} />
            <Route exact path="xml-sitemap-checker" element={<Sitemap />} />
            <Route exact path="about-us" element={<Aboutus />} />
            <Route exact path="contact-us" element={<Contactus />} />
            <Route exact path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="*" element={<Page404 />} />
          </Routes>
        

        <Footer />
      </BrowserRouter>
    
    </div>
  );
}

export default App;
