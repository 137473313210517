import React from 'react'

const Loader = () => {
    return (
        <>

            <div className="row mb-3">
                <div className="col-12 ">
                    <div className="card">
                        <div className="card-body">
                            <center><h5 className='loaderColor'>We are checking...</h5></center>
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border text-info" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Loader
