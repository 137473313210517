import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


export const contactInfo = createAsyncThunk('contactInfo', async(body) =>{
    const responce = await fetch(`https://api.webrizer.com/contact-us`, {
        method: 'POST',
        body: JSON.stringify({'fullname':body.fullname,'email':body.email,"phone":body.phone,"subject":body.subject,"message":body.message})
    })
    return await responce.json();
})

const contactSlice = createSlice({
    name: "contact",
    initialState: {
        contact: [],
    },
    reducers: {
    },
    extraReducers: (builder) => {

        /////////////////////////////////// contact us api  ///////////////////////////////////
        builder.addCase(contactInfo.pending, (state, action) => {
            state.contact = [];
            state.isLoading = true;
            state.Error = "";
        });

        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(contactInfo.fulfilled, (state, action) => {
            state.contact = action.payload;
            state.isLoading = false;
        });

        builder.addCase(contactInfo.rejected, (state, action) => {
            state.contact = [];
            state.isLoading = false;
            state.Error = "There is something wrong, please try again later";
        });


    }
})


export default contactSlice.reducer;