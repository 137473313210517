import React from 'react'
import { Link } from 'react-router-dom';
import logo from '../../image/app-logo/light-veriate.png';
import AnchorLink from 'react-anchor-link-smooth-scroll'

const Navbar = () => {
    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                <div className="container">
                    <Link className="navbar-brand fs-4 fw-bold" to="/"><img src={logo} alt="imgsr" className="app-logo" /></Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="nav-item"><Link className="nav-link" to="/" >Home</Link></li>
                            <li className="nav-item"><Link className="nav-link" to="title-tag-checker" >Title Tag checker</Link></li>
                            <li className="nav-item"><Link className="nav-link" to="meta-description-checker" >Meta description Checker</Link></li>
                            
                            <li className="nav-item"><Link className="nav-link" to="canonical-url-checker" >Canonical Tag Checker</Link></li>
                            <li className="nav-item"><AnchorLink className="nav-link" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample" smooth="true" >Free Tools</AnchorLink></li>
                            <li className="nav-item"><Link className="nav-link" to="about-us" >About Us</Link></li>
                            <li className="nav-item"><Link className="nav-link" to="contact-us" >Contact us</Link></li>
                            <li className="nav-item"><Link className="nav-link" to="privacy-policy" >Privacy Policy</Link></li>
                            
                        </ul>
                    </div>
                </div>
            </nav>


            <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                <div className="offcanvas-header border-bottom">
                    <h5 className="offcanvas-title" id="offcanvasExampleLabel">More Tools</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <div className="row">
                        <div className="col-12">
                            <p className="lead  text-muted">SEO FREE TOOLS</p>
                        </div>
                        
                        <div className="col-12">
                            <div className="row">
                                <div className="col-6">
                                    <Link className="anchorTag" to="title-tag-checker" >
                                        <div className="card border-0 dark-hover"  data-bs-dismiss="offcanvas" aria-label="Close">
                                            <div className="card-body text-center">
                                                
                                                <div className="feature bg-primary bg-gradient text-white rounded-3">
                                                    <i className="bi bi-card-text"></i>
                                                </div>
                                                <br />
                                                <span>Title Tag Checker</span>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                <div className="col-6">
                                    <Link className="anchorTag" to="meta-description-checker" >
                                        <div className="card border-0 dark-hover"  data-bs-dismiss="offcanvas" aria-label="Close">
                                            <div className="card-body text-center">
                                                
                                                <div className="feature bg-primary bg-gradient text-white rounded-3">
                                                    <i className="bi bi-file-earmark-word"></i>
                                                </div>
                                                <br />
                                                <span>Meta Description Checker</span>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                
                            </div>

                            <div className="row">
                                <div className="col-6">
                                    <Link className="anchorTag" to="meta-keyword-checker" >
                                        <div className="card border-0 dark-hover"  data-bs-dismiss="offcanvas" aria-label="Close">
                                            <div className="card-body text-center">
                                                <div className="feature bg-primary bg-gradient text-white rounded-3">
                                                    <i className="bi bi-file-earmark-text"></i>
                                                </div>
                                                <br />
                                                <span>Meta Keyword Checker</span>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                <div className="col-6">
                                    <Link className="anchorTag" to="canonical-url-checker" >
                                        <div className="card border-0 dark-hover"  data-bs-dismiss="offcanvas" aria-label="Close">
                                            <div className="card-body text-center">
                                                <div className="feature bg-primary bg-gradient text-white rounded-3">
                                                    <i className="bi bi-code-slash"></i>
                                                </div>
                                                <br />
                                                <span>Canonical URL Checker</span>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                <div className="col-6">
                                    <Link className="anchorTag" to="h1-tag-checker" >
                                        <div className="card border-0 dark-hover"  data-bs-dismiss="offcanvas" aria-label="Close">
                                            <div className="card-body text-center">
                                                <div className="feature bg-primary bg-gradient text-white rounded-3">
                                                    <i className="bi bi-type-h1"></i>
                                                </div>
                                                <br />
                                                <span>H1 Tag Checker</span>
                                            </div>
                                        </div>
                                    </Link>
                                </div>


                                <div className="col-6">
                                    <Link className="anchorTag" to="header-tag-checker" >
                                        <div className="card border-0 dark-hover"  data-bs-dismiss="offcanvas" aria-label="Close">
                                            <div className="card-body text-center">
                                                <div className="feature bg-primary bg-gradient text-white rounded-3">
                                                    <i className="bi bi-card-heading"></i>
                                                </div>
                                                <br />
                                                <span>Header Tag Checker</span>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                <div className="col-6">
                                    <Link className="anchorTag" to="html-attribute-lang-tag-checker" >
                                        <div className="card border-0 dark-hover"  data-bs-dismiss="offcanvas" aria-label="Close">
                                            <div className="card-body text-center">
                                                <div className="feature bg-primary bg-gradient text-white rounded-3">
                                                    <i className="bi bi-file-text"></i>
                                                </div>
                                                <br />
                                                <span>HTML Attribute Lang Checker</span>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                
                                
                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}

export default Navbar
