import React from 'react'
import { Link } from 'react-router-dom';
import aboutus1 from '../../image/webrizer_aboutus1.png';
import aboutus2 from '../../image/webrizer_aboutus2.jpg';

const Aboutus = () => {
    return (
        <>
            <header className="py-5">
                <div className="container px-5">
                    <div className="row">
                        <div className="col-12">
                            <Link to="/" className="btn btn-outline-dark"><i className="bi bi-arrow-left me-2"></i>Back</Link>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-xxl-8">
                            <div className="text-center my-2">
                                <h1 className="fw-bolder mb-3">Our mission is to make SEO friendly websites for everyone.</h1>
                                <p className="lead fw-normal text-muted mb-4">
                                    "Our mission is to equip website owners as well as digital marketers with the tools & also resources they require to enhance their online search engine rankings & expand their on-line presence. Our company believe that every business is entitled to a chance to succeed online, & also we're devoted to giving cost effective, effective, as well as easy to use SEO solutions that supply actual results.</p>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            
            <section className="py-5 bg-light" id="scroll-target">
                <div className="container px-5 my-5">
                    <div className="row gx-5 align-items-center">
                        <div className="col-lg-6"><img className="img-fluid rounded mb-5 mb-lg-0" src={aboutus1} alt="webrizer about us" /></div>
                        <div className="col-lg-6">
                            <h2 className="fw-bolder">Our founding</h2>
                            <p className="lead fw-normal text-muted mb-0">Our founding story begins with a group of passionate electronic marketing professionals who were annoyed with the limited SEO tools readily available to businesses of all sizes. We identified the need for a much more budget friendly & obtainable solution that might aid businesses optimize their on-line presence as well as attain their SEO goals.</p>
                        </div>
                    </div>
                </div>
            </section>
            
            <section className="py-5">
                <div className="container px-5 my-5">
                    <div className="row gx-5 align-items-center">
                        <div className="col-lg-6 order-first order-lg-last"><img className="img-fluid rounded mb-5 mb-lg-0" src={aboutus2} alt="webrizer about us" /></div>
                        <div className="col-lg-6">
                            <h2 className="fw-bolder">Growth &amp; beyond</h2>
                            <p className="lead fw-normal text-muted mb-0">As your SEO tools website continues to progress as well as grow, it is necessary to remain focused on your lasting goals as well as objectives. Right here are some tips for accomplishing sustainable growth & taking your website to the next level

                            </p>
                        </div>
                    </div>
                </div>
            </section>
           
            {/* <section className="py-5 bg-light">
                <div className="container px-5 my-5">
                    <div className="text-center">
                        <h2 className="fw-bolder">Our team</h2>
                        <p className="lead fw-normal text-muted mb-5">At Webrizer , we're proud to have a team of talented and dedicated professionals who share a passion for innovation, excellence, and customer service. Our team is made up of individuals from diverse backgrounds and experiences,</p>
                    </div>
                    <div className="row gx-5 row-cols-1 row-cols-sm-2 row-cols-xl-4 justify-content-center">
                        <div className="col mb-5 mb-5 mb-xl-0">
                            <div className="text-center">
                                <img className="img-fluid rounded-circle mb-4 px-4" src={deshmukhsc} alt="shripad deshmukh" />
                                <h5 className="fw-bolder">Shripad Deshmukh</h5>
                                <div className="fst-italic text-muted">Founder &amp; CEO</div>
                            </div>
                        </div>
                        <div className="col mb-5 mb-5 mb-xl-0">
                            <div className="text-center">
                                <img className="img-fluid rounded-circle mb-4 px-4" src={sandeepImg} alt="sandeep avhale" />
                                <h5 className="fw-bolder">Sandeep Avhale</h5>
                                <div className="fst-italic text-muted">CTO</div>
                            </div>
                        </div>
                        <div className="col mb-5 mb-5 mb-sm-0">
                            <div className="text-center">
                                <img className="img-fluid rounded-circle mb-4 px-4" src="https://dummyimage.com/150x150/ced4da/6c757d" alt="..." />
                                <h5 className="fw-bolder">Vijay Salve</h5>
                                <div className="fst-italic text-muted">UI/UX Designer</div>
                            </div>
                        </div>
                        <div className="col mb-5">
                            <div className="text-center">
                                <img className="img-fluid rounded-circle mb-4 px-4" src="https://dummyimage.com/150x150/ced4da/6c757d" alt="..." />
                                <h5 className="fw-bolder">Tushar Pardeshi</h5>
                                <div className="fst-italic text-muted">SEO Expert</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

        </>
    )
}

export default Aboutus
