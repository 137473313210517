import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getH1tag } from "../../redux/features/onPageSlice";
import { Link } from 'react-router-dom';
// import Sidebarads from '../layout/Sidebarads';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Errorshow from '../layout/Errorshow';
import Loader from '../layout/Loader';

const H1Tag = () => {

    const dispatch = useDispatch();
    // const [myUrl, SetMyUrl] = useState();
    const { errorCode, resone, result } = useSelector((state) => state.OnPagAPI.hone)
    const loading = useSelector((state) => state.OnPagAPI.isLoading)
    const error = useSelector((state) => state.OnPagAPI.Error)
    const website = useSelector((state) => state.OnPagAPI.website)
    // console.log(errorCode)
    // console.log(result)

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: {
            website: website,
        },
        validationSchema: Yup.object({
            website: Yup.string().url().required('Please Enter Valid Website URL'),
        }),
        onSubmit: (data) => {
            //console.warn('Form Submited', data);
            // let url = data.website.split("/");
            // SetMyUrl(url[0] + "//" + url[2])
            dispatch(getH1tag(data));
        }
    })

    return (
        <>
            <section className="py-5">

                <div className="container px-5 my-5">
                    <div className="row">
                        <div className="col-12">
                            <Link to="/" className="btn btn-outline-dark"><i className="bi bi-arrow-left me-2"></i>Back</Link>
                        </div>
                    </div>
                    <div className="text-center mb-5">
                        <h1 className="fw-bolder">H1 Tag Checker</h1>
                        <p className="lead  text-muted mb-0">A H1 tag mosaic is a device utilized to guarantee that the H1 tags on a website are correctly formatted & maximized for SEO. H1 tags are one of the most crucial tags on a website, as they are the preliminary thing that search engines take a look at when indexing a page.</p>
                    </div>
                    <div className="row gx-5">
                        <div className="col-xl-1"></div>
                        <div className="col-xl-10">

                            <div className="row">
                                {/* <div className="col-12 "> px-5 */}
                                <div className="col-12 ">
                                    <form onSubmit={handleSubmit} >
                                        <label className='form-label'><b>Enter Website URL*</b></label>
                                        <div className="input-group mb-4">
                                            <input type="text"
                                                id="website"
                                                name="website"
                                                className="form-control"
                                                placeholder="Website Url"
                                                aria-label="Recipient's username"
                                                onChange={handleChange}
                                                value={values.name}
                                                onBlur={handleBlur}
                                                aria-describedby="button-addon2" />

                                            <button className="btn btn-lg btn-dark" type="submit" id="button-addon2">Check</button>
                                        </div>

                                        {(errors.website && touched.website) ? <div className="alert alert-danger mb-4" role="alert">{errors.website}</div> : ""}
                                    </form>
                                </div>
                            </div>
                            <hr />


                            {loading ?
                                <Loader />
                                : ""}


                            {error && errorCode !== 200 ?
                                <Errorshow error={error} />
                                : ""}


                            {errorCode === 200 ?
                                <>
                                    <div className="row">
                                        <div className="col-12">
                                            <h5>Result : </h5>
                                            <div className={`alert alert-${result.alert}`} role="alert">
                                                <h4 className="alert-heading">Website : {website}</h4>
                                                <p className="mb-0 fs-6">{result.alertmsg}</p>
                                                <p className="mb-2 mt-3 badge bg-secondary fs-6">H1 Tags Count : {result.h1Count}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row gx-5">
                                        <div className="col-md-12">
                                            <table className="table table-striped table-fluid table-part cs-table-icon">
                                                <thead className="bg-dark text-white ">
                                                    <tr>
                                                        <th className="ms-3">H1 Header Tags List</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {result.Headings.map((hd) => (
                                                        <tr>
                                                            <td>&lt;{hd.head}&gt; {hd.Title} &lt;{hd.head}/&gt;</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>




                                </>

                                : ""}

                            {errorCode === 400 ?

                                <div className="row">
                                    <div className="col-12">
                                        <div className={`alert alert-${result.alert}`} role="alert">
                                            <h4 className="alert-heading">{resone}</h4>
                                        </div>
                                    </div>
                                </div>

                                : ""}



                            <div className="row ">
                                <div className="col-12">

                                    <div className="alert alert-info mb-4" role="alert">
                                        <h4 className="alert-heading">What it is</h4>
                                        <p>The H1 tag is the HTML element utilized to recommend the major heading of a web page. It is typically the largest text on the page & likewise is made use of to draw attention to the page's significant topic. The content within the H1 tag need to be brief & descriptive of the page's content.</p>
                                    </div>

                                    <div className="alert alert-warning mb-4" role="alert">
                                        <h4 className="alert-heading">How to fix it</h4>
                                        <ul>
                                            <li>1. Check the HTML code of the page to make sure the H1 tag is present.</li>
                                            <li>2. If the H1 tag is present, check the CSS code to make sure it is styled correctly.</li>
                                            <li>3. If the H1 tag is not present, add it to the HTML code.</li>
                                            <li>4. If the H1 tag is present but not styled correctly, adjust the CSS code to style it correctly.</li>

                                        </ul>
                                    </div>
                                </div>
                            </div>




                        </div>

                        <div className="col-xl-1">
                            {/* <Sidebarads /> */}
                        </div>


                    </div>

                    <center><h2 className="fw-bolder text-clr mb-5">Why H1 Tag Checker Tool? </h2></center>
                    <div className="row gx-5">
                        <div className="col-lg-12 lead text-muted text-justify">

                            <p>
                                The H1 tag checker tool is a vital tool for webmasters & SEO professionals. It is a tool that assists to guarantee that the H1 tags on a website are suitably formatted & enhanced for search engine optimization (SEO). The H1 tag is one of the most important tag on a website along with is used to reveal the primary topic of the page. It is the first thing that search engines have a look at when indexing a page, so it is necessary to make sure that it is correctly formatted & likewise boosted.
                            </p>
                            <p>
                                The H1 tag checker tool is a very easy yet effective tool that can assist webmasters & additionally SEO professionals to immediately in addition to quickly take a look at the H1 tags on their websites. It can be made use of to check the formatting of the H1 tags, along with to look for any kind of errors or inconsistencies. The tool can furthermore be used to look for any type of duplicate H1 tags, which can have a negative effect on SEO.
                            </p>
                            <p>
                                The H1 tag checker tool is simple to use as well as can be made use of to swiftly & conveniently inspect the H1 tags on a website. It can be used to look for any kind of type of errors or inconsistencies in the formatting of the H1 tags, along with try to find any kind of duplicate H1 tags. The tool can also be utilized to seek any kind of other issues that may be influencing the SEO of the website.
                            </p>
                            <p>
                                The H1 tag checker tool is a crucial tool for webmasters as well as SEO professionals. It can assist to guarantee that the H1 tags on a website are appropriately formatted & also maximized for SEO. It can likewise be utilized to quickly & also conveniently check for any kind of errors or inconsistencies in the formatting of the H1 tags, in addition to look for any kind of sort of reproduce H1 tags.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default H1Tag
