import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getSitemap } from "../../redux/features/onPageSlice";
import { Link } from 'react-router-dom';
import { useFormik } from 'formik'
import * as Yup from 'yup'
// import Sidebarads from '../layout/Sidebarads';
import Errorshow from '../layout/Errorshow';
import Loader from '../layout/Loader';

const Sitemap = () => {

    const dispatch = useDispatch();
    const { errorCode, resone, result } = useSelector((state) => state.OnPagAPI.sitemap)
    const loading = useSelector((state) => state.OnPagAPI.isLoading)
    const error = useSelector((state) => state.OnPagAPI.Error)
    const website = useSelector((state) => state.OnPagAPI.website)

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: {
            website: website,
        },
        validationSchema: Yup.object({
            website: Yup.string().url().required('Please Enter Valid Website URL'),
        }),
        onSubmit: (data) => {
            dispatch(getSitemap(data));
        }
    })

    return (
        <>
            <section className="py-5">

                <div className="container px-5 my-5">
                    <div className="row">
                        <div className="col-12">
                            <Link to="/" className="btn btn-outline-dark"><i className="bi bi-arrow-left me-2"></i>Back</Link>
                        </div>
                    </div>
                    <div className="text-center mb-5">
                        <h1 className="fw-bolder">XML Sitemap Checker</h1>
                        <p className="lead  text-muted mb-0">XML Sitemap Checker is a tool that is used to verify the integrity of an XML Sitemap file. It assists to make certain that the information included in the Sitemap is exact & likewise complies with the XML Sitemap protocol. This checker will certainly examine the Sitemap & also report any type of kind of errors that it uncovers.</p>
                    </div>
                    <div className="row gx-5">
                        <div className="col-xl-1"></div>
                        <div className="col-xl-10">
                            <div className="row">
                                {/* <div className="col-12 "> px-5 */}
                                <div className="col-12 ">
                                    <form onSubmit={handleSubmit} >
                                        <label className='form-label'><b>Enter Website URL*</b></label>
                                        <div className="input-group mb-4">
                                            <input type="text"
                                                id="website"
                                                name="website"
                                                className="form-control"
                                                placeholder="Website Url"
                                                aria-label="Recipient's username"
                                                onChange={handleChange}
                                                value={values.name}
                                                onBlur={handleBlur}
                                                aria-describedby="button-addon2" />

                                            <button className="btn btn-lg btn-dark" type="submit" id="button-addon2">Check</button>
                                        </div>

                                        {(errors.website && touched.website) ? <div className="alert alert-danger mb-4" role="alert">{errors.website}</div> : ""}
                                    </form>
                                </div>
                            </div>
                            <hr />


                            {loading ?
                                <Loader />
                                : ""}


                            {error && errorCode !== 200 ?
                                <Errorshow error={error} />
                                : ""}


                            {errorCode === 200 ?

                                <div className="row">
                                    <div className="col-12">
                                        <h5>Result : </h5>
                                        <div className={`alert alert-${result.alert}`} role="alert">
                                            <h4 className="alert-heading">Website : {website}</h4>

                                            <p>{result.xmlMsg}</p>
                                            <p className="mb-0 "><b className='badge bg-primary fs-6'>XML Sitemap URL :</b> <b className='badge bg-secondary fs-6'>{result.link}</b></p>

                                            <p className="mt-2">{result.alertmsg}</p>


                                        </div>
                                    </div>
                                </div>

                                : ""}

                            {errorCode === 400 ?

                                <div className="row">
                                    <div className="col-12">
                                        <div className={`alert alert-${result.alert}`} role="alert">
                                            <h4 className="alert-heading">{resone}</h4>
                                        </div>
                                    </div>
                                </div>

                                : ""}

                            <div className="row ">
                                <div className="col-12">

                                    <div className="alert alert-info mb-4" role="alert">
                                        <h4 className="alert-heading">What it is</h4>
                                        <p>XML Sitemap Checker is a tool that is used to verify the integrity of an XML Sitemap file. It assists to see to it that the information had in the Sitemap is specific & additionally complies with the XML Sitemap protocol. This checker will definitely check the Sitemap & additionally report any type of kind of kind of errors that it discovers. It also inspects to see to it that the Sitemap includes each of the pages that are detailed in the Sitemap. XML Sitemap Checker is really vital for any kind of website that intends to ensure that its content is properly indexed by search engines. It can aid to enhance website performance along with make certain that the website is correctly crept & indexed.</p>
                                    </div>

                                    <div className="alert alert-warning mb-4" role="alert">
                                        <h4 className="alert-heading">How to fix it</h4>
                                        <p>Fixing an XML sitemap checker can be a reasonably simple process, supplied you have the right tools as well as knowledge.</p>

                                        <p>The first step is to identify the source of the problem. This can be done by running a diagnostic on the XML sitemap checker, searching for any errors or warnings that may be present. Once you have recognized the source of the issue, you can after that begin to work in the direction of fixing it.</p>

                                        <p>The next step is to guarantee that the XML sitemap is valid & also free from errors. This can be done by running an XML validation tool, which will inspect the syntax & structure of the sitemap to ensure there are no errors or issues. If you find any errors or issues, you must correct them asap.</p>

                                        <p>Once the XML sitemap stands, you can then check for any issues with the content of the sitemap. This includes monitoring for any busted links, outdated content, or inaccurate URLs. If any of these issues are found, they should be fixed to ensure the XML sitemap is functioning appropriately.</p>

                                        <p>Finally, you should inspect that the XML sitemap is being indexed properly by the search engines. This can be done by using a sitemap indexing tool that will inspect the sitemap to ensure that it is being indexed & also complied with by the online search engine bots.</p>

                                        <p>By following these steps, you must have the ability to successfully fix any issues with an XML sitemap checker. With these steps, you can make certain that your sitemap is operating properly & being indexed by the search engines.</p>
                                    </div>
                                </div>
                            </div>




                        </div>

                        <div className="col-xl-1">
                            {/* <Sidebarads /> */}
                        </div>


                    </div>

                    <center><h2 className="fw-bolder text-clr mb-5">Why robots.txt Checker Tool? </h2></center>
                    <div className="row gx-5">
                        <div className="col-lg-12 lead text-muted text-justify">

                            <p>XML sitemap checker tools are unbelievably valuable for website owners & also developers. They supply a detailed overview of a website's structure as well as content, making it easier for internet search engine crawlers to locate as well as index content. XML sitemaps are specifically vital for larger websites that might have numerous pages, as they aid search engines in promptly identifying new & also upgraded content.</p>

                            <p>XML sitemaps also help website owners keep control over how their content is indexed as well as offered within internet search engine results pages. This assists make sure that users have the ability to swiftly locate one of the most appropriate as well as up-to-date content.</p>

                            <p>XML sitemap checker tools can aid website owners recognize any type of errors with their sitemaps & swiftly repair them. This helps guarantee that search engine crawlers are able to gain access to as well as index content appropriately. In addition, these tools can be used to track changes to the sitemap with time, allowing website owners to determine any issues quickly as well as take the proper action to fix them.</p>

                            <p>Overall, XML sitemap checker tools are important for website owners as well as developers, helping them guarantee their website is maximized for search engines. They provide a detailed overview of a website's structure as well as content, allowing search engine crawlers to rapidly recognize brand-new & upgraded content. Additionally, they help website owners preserve control over just how their content is indexed & offered within internet search engine results pages. Lastly, these tools can be made use of to recognize & fix any kind of errors with sitemaps, in addition to tracking changes to the sitemap in time.</p>

                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default Sitemap
