import React from 'react'
import { Link } from 'react-router-dom';
import headerImg from '../../image/app-logo/light-veriate.png';
// import Multplex from './Multplex';

const Footer = () => {
    return (
        <div>

            {/* <Multplex /> */}

            <footer className="page-footer font-small blue pt-4 bg-dark text-light">
                <div className="container-fluid text-md-left">

                    <div className="row p-2 p-md-4">
                        <div className="col-md-4 mt-md-0 mt-3">
                            <img src={headerImg} className="app-logo mb-3" alt='footer-img' />
                            <p>Looking for the best SEO Audit Tool to analyze and grade your website? Webrizer is the web's best SEO Checker. Improve your website, rank better in Search Engines and win more customers. Audit your Website today</p>
                        </div>


                        <hr className="clearfix w-100 d-md-none pb-3" />

                        <div className="col-md-2 mb-md-0 mb-3">

                            <h5 className="text-uppercase">SEO FREE TOOLS</h5>
                            <ul className="list-unstyled px-2">
                                <li>
                                    <Link to="title-tag-checker">Title Tag Checker</Link>
                                </li>
                                <li>
                                    <Link to="meta-description-checker">Meta Description Checker</Link>
                                </li>
                                <li>
                                    <Link to="meta-keyword-checker">Meta Keyword Checker</Link>
                                </li>



                            </ul>
                        </div>

                        <div className="col-md-2 mb-md-0 mb-5">

                            <h5 className="text-uppercase" style={{ color: "#212529" }}>SEO FREE TOOLS</h5>
                            <ul className="list-unstyled px-2 mb-0">

                                <li>
                                    <Link to="canonical-url-checker">Canonical URL Checker</Link>
                                </li>
                                <li>
                                    <Link to="h1-tag-checker">H1 Tag Checker</Link>
                                </li>


                                <li>
                                    <Link to="header-tag-checker">Header Tag Checker</Link>
                                </li>



                            </ul>
                        </div>

                        <div className="col-md-4 mb-md-0 mb-5">

                            <h5 className="text-uppercase" style={{ color: "#212529" }}>SEO FREE TOOLS</h5>
                            <ul className="list-unstyled px-2 mb-0">


                                <li>
                                    <Link to="html-attribute-lang-tag-checker">HTML Attribute Lang Checker</Link>
                                </li>
                                <li>
                                    <Link to="robots-txt-checker">Robots.txt Checker</Link>
                                </li>
                                <li>
                                    <Link to="robots-txt-checker">XML Sitemap Checker</Link>
                                </li>

                            </ul>
                        </div>






                    </div>
                </div>

                <div className="footer-copyright text-center py-3">
                    Copyright &copy; Webrizer Tech 2023
                </div>
            </footer>
        </div>
    )
}

export default Footer
