import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getCanonical } from "../../redux/features/onPageSlice";
import { Link } from 'react-router-dom';
// import Sidebarads from '../layout/Sidebarads';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Errorshow from '../layout/Errorshow';
import Loader from '../layout/Loader';

const CanonicalTag = () => {

    const dispatch = useDispatch();
    // const [myUrl, SetMyUrl] = useState();
    const { errorCode, resone, result } = useSelector((state) => state.OnPagAPI.canonical)
    const loading = useSelector((state) => state.OnPagAPI.isLoading)
    const error = useSelector((state) => state.OnPagAPI.Error)
    const website = useSelector((state) => state.OnPagAPI.website)
    // console.log(errorCode)
    // console.log(result)

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: {
            website: website,
        },
        validationSchema: Yup.object({
            website: Yup.string().url().required('Please Enter Valid Website URL'),
        }),
        onSubmit: (data) => {
            //console.warn('Form Submited', data);
            // let url = data.website.split("/");
            // SetMyUrl(url[0] + "//" + url[2])
            dispatch(getCanonical(data));
        }
    })

    return (
        <>
            <section className="py-5">

                <div className="container px-5 my-5">
                    <div className="row">
                        <div className="col-12">
                            <Link to="/" className="btn btn-outline-dark"><i className="bi bi-arrow-left me-2"></i>Back</Link>
                        </div>
                    </div>
                    <div className="text-center mb-5">
                        <h1 className="fw-bolder">Canonical URL Checker</h1>
                        <p className="lead  text-muted mb-0">A Canonical URL checker is a crusial web tool that indentify if the canonical URL of a page is correct/not. It checks if the page indicate itself as the canonical URL. it is mejor impact on website page</p>
                    </div>
                    <div className="row gx-5">
                        <div className="col-xl-1"></div>
                        <div className="col-xl-10">

                            <div className="row">
                                {/* <div className="col-12 "> px-5 */}
                                <div className="col-12 ">
                                    <form onSubmit={handleSubmit} >
                                        <label className='form-label'><b>Enter Website URL*</b></label>
                                        <div className="input-group mb-4">
                                            <input type="text"
                                                id="website"
                                                name="website"
                                                className="form-control"
                                                placeholder="Website Url"
                                                aria-label="Recipient's username"
                                                onChange={handleChange}
                                                value={values.name}
                                                onBlur={handleBlur}
                                                aria-describedby="button-addon2" />

                                            <button className="btn btn-lg btn-dark" type="submit" id="button-addon2">Check</button>
                                        </div>

                                        {(errors.website && touched.website) ? <div className="alert alert-danger mb-4" role="alert">{errors.website}</div> : ""}
                                    </form>
                                </div>
                            </div>
                            <hr />


                            {loading ?
                                <Loader />
                                : ""}


                            {error && errorCode !== 200 ?
                                <Errorshow error={error} />
                                : ""}


                            {errorCode === 200 ?

                                <div className="row">
                                    <div className="col-12">
                                        <h5>Result : </h5>
                                        <div className={`alert alert-${result.alert}`} role="alert">
                                            <h4 className="alert-heading">Website : {website}</h4>

                                            <p className="fs-5"><span className="mb-0 badge bg-secondary fs-6">Canonical URL  : </span> {result.canonical}</p>

                                            <p className="mt-2">{result.alertmsg}</p>
                                        </div>
                                    </div>
                                </div>

                                : ""}

                            {errorCode === 400 ?

                                <div className="row">
                                    <div className="col-12">
                                        <div className={`alert alert-${result.alert}`} role="alert">
                                            <h4 className="alert-heading">{resone}</h4>
                                        </div>
                                    </div>
                                </div>

                                : ""}

                            <div className="row ">
                                <div className="col-12">

                                    <div className="alert alert-info mb-4" role="alert">
                                        <h4 className="alert-heading">What it is</h4>
                                        <p>Search Engines use the Canonical URL to determine a page's primary URL. It is possible to have multiple versions of a URL due to parameters passing through or a www and non-www version, leading to duplicate content. It is for this reason that Google recommends that each page specify a Canonical.</p>
                                    </div>

                                    <div className="alert alert-warning mb-4" role="alert">
                                        <h4 className="alert-heading">How to fix it</h4>
                                        <p>It might be essential to identify which version of the page is the main preferred version. Frequently, the CMS will certainly handle this or give the ability to specify it.</p>
                                    </div>
                                </div>
                            </div>




                        </div>

                        <div className="col-xl-1">
                            {/* <Sidebarads />  */}
                        </div>


                    </div>

                    <center><h2 className="fw-bolder text-clr mb-5">Why Canonical URL Checker Tool? </h2></center>
                    <div className="row gx-5">
                        <div className="col-lg-12 lead text-muted text-justify">

                            <p>
                                Canonical URL checker tools are crucial for any website owner attempting to enhance their website for SEO. Canonical URLs, also called rel="canonical", are HTML tag that tell search engines which version of a page is the main version. This aids protect against replicating content issues, which can be harmful to a website's SEO ranking.
                            </p>
                            <p>
                                Using a canonical URL checker tool enables website owners to promptly assess their website & make certain the appropriate canonical URLs remain in place. Canonical URL checker tools been available in numerous forms. Some are incorporated into website design or SEO tools, while others are standalone applications.
                            </p>
                            <p>
                                When selecting a canonical URL checker tool, it is very important to consider the complexity of the tool. As an example, some tools might only examine a solitary page, while others may have the ability to monitor full websites. Furthermore, some tools may supply a more comprehensive analysis than others. Choosing the best tool relies on the needs of the website & also the level of analysis required.
                            </p>
                            <p>
                                When a canonical URL checker tool is selected, it is necessary to utilize it routinely. This guarantees the website is routinely checked for any kind of changes that may influence the canonical URL. Additionally, it is necessary to evaluate the reports generated by the tool to make sure all canonical URL are appropriately carried out.
                            </p>
                            <p>
                                Along with inspecting canonical URLs, there are numerous other SEO best practices website owners must comply with. These include optimizing page titles as well as meta descriptions, developing one-of-a-kind as well as interesting content, maximizing images as well as videos, as well as developing interior & also exterior links.
                            </p>
                            <p>
                                Canonical URL checker tools are essential tools for website owners wanting to maximize their SEO performance. By regularly evaluating the canonical URLs on a website, website owners can guarantee the website is appropriately maximized for online search engine rankings. Furthermore, website owners need to comply with other SEO better practices to ensure their website is as maximized as possible.
                            </p>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CanonicalTag
