import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getRobot } from "../../redux/features/onPageSlice";
import { Link } from 'react-router-dom';
import { useFormik } from 'formik'
import * as Yup from 'yup'
// import Sidebarads from '../layout/Sidebarads';
import Errorshow from '../layout/Errorshow';
import Loader from '../layout/Loader';

const RobotsTxt = () => {

    const dispatch = useDispatch();
    const { errorCode, resone, result } = useSelector((state) => state.OnPagAPI.robot)
    const loading = useSelector((state) => state.OnPagAPI.isLoading)
    const error = useSelector((state) => state.OnPagAPI.Error)
    const website = useSelector((state) => state.OnPagAPI.website)

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: {
            website: website,
        },
        validationSchema: Yup.object({
            website: Yup.string().url().required('Please Enter Valid Website URL'),
        }),
        onSubmit: (data) => {
            dispatch(getRobot(data));
        }
    })

    return (
        <>
            <section className="py-5">

                <div className="container px-5 my-5">
                    <div className="row">
                        <div className="col-12">
                            <Link to="/" className="btn btn-outline-dark"><i className="bi bi-arrow-left me-2"></i>Back</Link>
                        </div>
                    </div>
                    <div className="text-center mb-5">
                        <h1 className="fw-bolder">Robots.txt Checker</h1>
                        <p className="lead  text-muted mb-0">Robots.txt Checker with SEO title suggestion is a tool that helps website owners as well as SEO professionals check the robots.txt file of a website & also suggests SEO-friendly titles for the website's pages.</p>
                    </div>
                    <div className="row gx-5">
                        <div className="col-xl-1"></div>
                        <div className="col-xl-10">

                            <div className="row">
                                {/* <div className="col-12 "> px-5 */}
                                <div className="col-12 ">
                                    <form onSubmit={handleSubmit} >
                                        <label className='form-label'><b>Enter Website URL*</b></label>
                                        <div className="input-group mb-4">
                                            <input type="text"
                                                id="website"
                                                name="website"
                                                className="form-control"
                                                placeholder="Website Url"
                                                aria-label="Recipient's username"
                                                onChange={handleChange}
                                                value={values.name}
                                                onBlur={handleBlur}
                                                aria-describedby="button-addon2" />

                                            <button className="btn btn-lg btn-dark" type="submit" id="button-addon2">Check</button>
                                        </div>

                                        {(errors.website && touched.website) ? <div className="alert alert-danger mb-4" role="alert">{errors.website}</div> : ""}
                                    </form>
                                </div>
                            </div>
                            <hr />


                            {loading ?
                                <Loader />
                                : ""}


                            {error && errorCode !== 200 ?
                                <Errorshow error={error} />
                                : ""}


                            {errorCode === 200 ?

                                <div className="row">
                                    <div className="col-12">
                                        <h5>Result : </h5>
                                        <div className={`alert alert-${result.alert}`} role="alert">
                                            <h4 className="alert-heading">Website : {website}</h4>

                                            <p>{result.robotMsg}</p>
                                            <p className="mb-0 "><b className='badge bg-primary fs-6'>Robots.txt URL :</b> <b className='badge bg-secondary fs-6'>{result.link}</b></p>
                                            <p className="mt-2">{result.alertmsg}</p>


                                        </div>
                                    </div>
                                </div>

                                : ""}

                            {errorCode === 400 ?

                                <div className="row">
                                    <div className="col-12">
                                        <div className={`alert alert-${result.alert}`} role="alert">
                                            <h4 className="alert-heading">{resone}</h4>
                                        </div>
                                    </div>
                                </div>

                                : ""}

                            <div className="row ">
                                <div className="col-12">

                                    <div className="alert alert-info mb-4" role="alert">
                                        <h4 className="alert-heading">What it is</h4>
                                        <p>Robots.txt Checker with SEO title suggestion is a tool that aids website owners along with SEO professionals inspect the robots.txt file of a website & additionally suggests SEO-friendly titles for the website's pages. It aids to make sure that the robots.txt file is correctly configured & that the website's pages are made the most of for Internet search engine rankings. The tool also supplies suggestions for improving the website's SEO titles, which can help to boost the website visibility in SEO results.</p>
                                    </div>

                                    <div className="alert alert-warning mb-4" role="alert">
                                        <h4 className="alert-heading">How to fix it</h4>
                                        <ul>
                                            <li>1. Guarantee that the robots.txt file is properly formatted as well as has legitimate directives.</li>
                                            <li>2. Check for any potential conflicts between the robots.txt file & the website's content.</li>
                                            <li>3. See to it that the robots.txt file is not blocking any vital content from being indexed.</li>
                                            <li>4. Guarantee that the robots.txt file is not obstructing any crucial resources such as images, CSS, as well as JavaScript.</li>
                                            <li>5. Look for any type of potential issues with the robots.txt file that might lead to search engine penalties.</li>
                                            <li>6. Offer SEO title suggestions for each & every page to help enhance the website's search engine positions.</li>
                                            <li>7. Display the robots.txt declare any type of changes that might affect the website's search engine rankings.</li>
                                            <li>8. Offer regular reports on the performance of the website's robots.txt file.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>




                        </div>

                        <div className="col-xl-1">
                            {/* <Sidebarads /> */}
                        </div>


                    </div>

                    <center><h2 className="fw-bolder text-clr mb-5">Why robots.txt Checker Tool? </h2></center>
                    <div className="row gx-5">
                        <div className="col-lg-12 lead text-muted text-justify">

                            <p>
                                Robots.txt Checker is a tool used to help website owners assess their robots.txt files. It is made use of to establish whether the robots.txt file is appropriately established & also if online search engine crawlers are enabled to access the website's content. The tool checks the syntax of the robots.txt file, tries to find any issues or errors, & aids identify non-compliant directives.
                            </p>
                            <p>
                                Robots.txt Checker can also be used to examine the compatibility of the robots.txt file with different search engines. It assists website owners to make sure that their content is properly indexed as well as crawled by the internet search engine crawlers. In Addition, Robots.txt Checker can be utilized to examine the robots.txt file against the most up to date rules & also standards set by different search engines.
                            </p>
                            <p>
                                The use of Robots.txt Checker is important for website owners who intend to make sure that their content is properly indexed as well as crept by the search engines. It aids website owners to identify any type of potential issues in their robots.txt file as well as make the required changes to ensure that the content is effectively indexed & crept. Furthermore, it helps website owners to inspect the compatibility of the robots.txt file with various search engines.
                            </p>




                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default RobotsTxt
