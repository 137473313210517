import React from 'react'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { contactInfo } from '../../redux/features/contactSlice';
import { useFormik } from 'formik'
import * as Yup from 'yup'



const Contactus = () => {

    const dispatch = useDispatch();
    const { errorCode, result } = useSelector((state) => state.contactAPI.contact)
    const loading = useSelector((state) => state.contactAPI.isLoading)
    const error = useSelector((state) => state.contactAPI.Error)


    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: {
            fullname: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
        },
        validationSchema: Yup.object({
            fullname: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('Please Enter fullname'),
            email: Yup.string().email('Invalid email').required('Required'),
            phone: Yup.string().required('Please Enter Phone No'),
            subject: Yup.string().required('Please Enter Email Subject'),
            message: Yup.string().required('Please Enter Message Body'),
        }),
        onSubmit: (data) => {
            console.warn('Form Submited', data);
            dispatch(contactInfo(data));
        }
    })



    return (
        <>

            <section className="py-5">
                <div className="container px-5">

                    <div className="row">
                        <div className="col-12">
                            <Link to="/" className="btn btn-outline-dark"><i className="bi bi-arrow-left me-2"></i>Back</Link>
                        </div>
                    </div>

                    <div className="bg-light rounded-3 py-5 px-4 px-md-5 mb-5">
                        <div className="text-center mb-5">
                            <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i className="bi bi-envelope"></i></div>
                            <h1 className="fw-bolder">Get in touch</h1>
                            <p className="lead fw-normal text-muted mb-0">We'd love to hear from you</p>
                        </div>
                        <div className="row gx-5 justify-content-center">
                            <div className="col-lg-8 col-xl-6">
                                <form onSubmit={handleSubmit}>

                                    <div className="form-floating mb-3">
                                        <input type="text"
                                            id="fullname"
                                            name="fullname"
                                            className="form-control"
                                            placeholder="Enter your full name"
                                            aria-label="Enter your full name"
                                            onChange={handleChange}
                                            value={values.name}
                                            onBlur={handleBlur}
                                            aria-describedby="button-addon2" />

                                        <label htmlFor="fullname">Full name</label>
                                        {(errors.fullname && touched.fullname) ? <div className="invalid-feedback" >{errors.fullname}</div> : ""}
                                    </div>

                                    <div className="form-floating mb-3">
                                        <input type="text"
                                            id="email"
                                            name="email"
                                            className="form-control"
                                            placeholder="Enter your email"
                                            aria-label="Enter your email"
                                            onChange={handleChange}
                                            value={values.name}
                                            onBlur={handleBlur}
                                            aria-describedby="button-addon2" />

                                        <label htmlFor="email">Email</label>
                                        {(errors.email && touched.email) ? <div className="invalid-feedback" data-sb-feedback="email:required">{errors.email}</div> : ""}
                                    </div>

                                    <div className="form-floating mb-3">
                                        <input type="text"
                                            id="phone"
                                            name="phone"
                                            className="form-control"
                                            placeholder="Enter your phone no"
                                            aria-label="Enter your phone no"
                                            onChange={handleChange}
                                            value={values.name}
                                            onBlur={handleBlur}
                                            aria-describedby="button-addon2" />

                                        <label htmlFor="phone">Phone No</label>
                                        {(errors.phone && touched.phone) ? <div className="invalid-feedback" data-sb-feedback="phone:required">{errors.phone}</div> : ""}
                                    </div>

                                    <div className="form-floating mb-3">
                                        <input type="text"
                                            id="subject"
                                            name="subject"
                                            className="form-control"
                                            placeholder="Enter subject"
                                            aria-label="Enter subject"
                                            onChange={handleChange}
                                            value={values.name}
                                            onBlur={handleBlur}
                                            aria-describedby="button-addon2" />

                                        <label htmlFor="subject">Subject</label>
                                        {(errors.subject && touched.subject) ? <div className="invalid-feedback" data-sb-feedback="subject:required">{errors.subject}</div> : ""}
                                    </div>

                                    <div className="form-floating mb-3">
                                        <textarea
                                            id="message"
                                            name="message"
                                            className="form-control"
                                            placeholder="Enter Message"
                                            aria-label="Enter Message"
                                            onChange={handleChange}
                                            value={values.name}
                                            onBlur={handleBlur}
                                            aria-describedby="button-addon2" />

                                        <label htmlFor="message">Message</label>
                                        {(errors.message && touched.message) ? <div className="invalid-feedback" data-sb-feedback="message:required">{errors.message}</div> : ""}
                                    </div>


                                    {error && errorCode !== 200 ?
                                        <>
                                            <div className="row">
                                                <div className="col-12">

                                                    <div className="alert alert-danger" role="alert">
                                                        <h5 className="alert-heading">{error}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        : ""}


                                    {errorCode === 200 ?

                                        <div className="row">
                                            <div className="col-12">
                                                <div className="alert alert-success" role="alert">
                                                    <h6 className="alert-heading">{result.status}</h6>
                                                </div>
                                            </div>
                                        </div>

                                        : ""}

                                    {errorCode === 400 ?

                                        <div className="row">
                                            <div className="col-12">
                                                <div className="alert alert-danger" role="alert">
                                                    <h6 className="alert-heading">{result.status}</h6>
                                                </div>
                                            </div>
                                        </div>

                                        : ""}

                                    <div className="d-grid">
                                        <button className="btn btn-primary btn-lg" type="submit" id="button-addon2">

                                            {loading ?
                                                <>
                                                    Sending..
                                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    <span class="visually-hidden">Loading...</span></>
                                                : "Submit"}


                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className="row gx-5 row-cols-2 row-cols-lg-4 py-5">

                    
                        <div className="col">
                            <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i className="bi bi-chat-dots"></i></div>
                            <div className="h5 mb-2">Chat with us</div>
                            <p className="text-muted mb-0">Chat live with one of our support specialists. </p>
                        </div>
                        
                       
                        <div className="col">
                            <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i className="bi bi-telephone"></i></div>
                            <div className="h5">Call us</div>
                            <p className="text-muted mb-0">Call us during normal business hours at 9823608146</p>
                        </div>
                        <div className="col">
                        <div className="col">
                            <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i className="bi bi-geo-alt-fill"></i></div>
                                <div className="h5">Offical Address</div>
                                <p className="text-muted mb-0">BM Jhaveri complex, Johari wada, Chhatrapati sambhaji nagar, Maharashtra 4310001 </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Contactus
