import React from 'react'
import { Link } from 'react-router-dom';
import img404 from '../../image/404.jpg';

const Page404 = () => {
    return (
        <>
            <header className="py-5">
                <div className="container px-5">


                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-xxl-6">
                            <div className="container-404">
                                <img src={img404} alt="Page 404" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                
                    <div className="row my-5">
                        <div className="col-12">
                            <center><Link to="/" className="btn btn-outline-dark"><i className="bi bi-arrow-left me-2"></i>Back To Home</Link></center>
                        </div>
                    </div>
                   
                </div>
            </header>
        </>
    )
}

export default Page404
