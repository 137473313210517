import React from 'react'

const Errorshow = (props) => {
  return (
    <>
        <div className="row">
            <div className="col-12">
                <h5>Result : </h5>
                <div className="alert alert-danger" role="alert">
                    <h5 className="alert-heading">{props.error}</h5>
                </div>
            </div>
        </div>
    </>
  )
}
export default Errorshow
